import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAttributeGroup, saveAttributeGroup } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import AttributeGroupForm from './AttributeGroupForm';

const EditAttributeGroups = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: attributegroup, isLoading } = useQuery( [ 'attributegroup', id ], () => getAttributeGroup( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveAttributeGroup( id, data );

    navigate( '/manageServices/attribute_groups' );
  };

  return (
    <div className='editAttributeGroups'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit { attributegroup?.name?.en }</div>
          <button className='btnAdd' type='button'
                  onClick={ () => navigate( '/manageServices/attribute_groups' ) }>BACK TO
            LISTING
          </button>
        </div>

        <AttributeGroupForm save={ save } item={ attributegroup }/>
      </div>
    </div>
  );
};

export default EditAttributeGroups;
