import React from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import InputTypeFileField from './InputTypeFileField';
import StatusSwitch from './StatusSwitch';
import TextareaField from './TextareaField';
import ImageField from './ImageField';
import GenderField from './GenderField';

interface IField {
  name: string;
  subTitle?: string;
  inputName?: string;
  type: 'input' | 'image' | 'select' | 'file' | 'checkbox' | 'textarea' | 'gender';
  props?: any;
  containerClassName?: string;
}

interface IProps<T> {
  fields: { [ key: string ]: IField };
  //данные конкретного объекта, например админа
  item?: T;
}


const CustomForm = <T extends object>( { fields, item }: IProps<T> ) => {
  return (
    <div className='column containerForm'>

      { Object.keys( fields ).map( name => {
        const field = fields[ name ];
        //@ts-ignore
        let defaultValue = item ? item[ name ] : undefined;
        let inputName = field.inputName || name;
        let inputNames = field.inputName?.split( '.' ); //['name','en']
        if ( inputNames ) {
          inputName = inputNames[ 0 ];
          inputNames.forEach( ( n, i ) => {
            if ( i ) {
              defaultValue = defaultValue ? defaultValue[ n ] : undefined;
              inputName += `[${ n }]`;
            }
          } );
        }

        let Component: any = () => <div/>;
        //все поля забить сюда - карты и тд
        switch ( field.type ) {
          case 'input':
            Component = InputField;
            break;
          case 'textarea':
            Component = TextareaField;
            break;
          case 'file':
            Component = InputTypeFileField;
            break;
          case 'checkbox':
            Component = StatusSwitch;
            break;
          case 'select':
            Component = SelectField;
            break;
          case 'gender':
            Component = GenderField;
            break;
          case 'image':
            Component = ImageField;
            break;
        }

        return (
          <Component
            key={ name }
            name={ inputName || name }
            label={ field.name }
            subTitle={ field.subTitle }
            defaultValue={ defaultValue }
            { ...field.props }
          />
        );
      } ) }
    </div>
  );
};

export default CustomForm;
