import { apiGet, apiPost } from '../api';
import { IReview, IReviewID, IReviews } from '../types/reviews';
import { FilterParams } from './filter';

export const getListReviews = async ( filter?: FilterParams ) => {
	return await apiGet<IReviews>( { url: `/reviews?${ filter?.toQueryParams() }` } );
};

export const getReview = async ( id: string ) => {
	return await apiGet<IReview>( { url: `/review/${ id }` } );
};

export const saveReview = async ( id: string, data: any ) => {
	return await apiPost<IReviewID>( {
		url: `/reviews/${ id }`,
		postData: data
	} );
};

export const createReview = async ( data: any ) => {
	return await apiPost<IReviewID>( {
		url: '/reviews',
		postData: data
	} );
};
