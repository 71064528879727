import React from 'react';
import '../../styles/popup.scss';
import { FiUsers, FiXCircle } from 'react-icons/fi';
import { useStore } from 'effector-react';
import { ICustomerID } from '../../types/customers';
import { $profileCustomer, hideProfileCustomer } from '../../stores/modalContainer';
import { useQuery } from 'react-query';
import { getCustomerID } from '../../stores/customers';
import Loader from '../Loader';
import { staticResource } from '../../utils/resources';
import { Rating } from 'react-simple-star-rating';
import Moment from 'react-moment';

const ProfileCustomer = () => {

  const customerId = useStore( $profileCustomer );

  const {
    data: customer,
    isLoading
  } = useQuery<ICustomerID>( [ customerId, 'customer' ], () => getCustomerID( customerId ) );
  if ( isLoading ) return <Loader/>;

  return (
    <div className='profile popup'>
      <div className='column container mb-20'>
        <div className='row width-100 justify-between border-bottom'>
          <div className='width-23 row align-center justify-between padding20'>
            <FiUsers className='iconPopup'/>
            <div className='textTitlePopup'>User Details</div>
          </div>
          <button
            className='padding20'
            type='button'
            onClick={ () => hideProfileCustomer() }>
            <FiXCircle className='iconPopup'/>
          </button>
        </div>
        <div className='column containerInPopup'>
          <div className='row border-bottom'>
            <div className='width-30 padding10 borderRight'>
              <img src={ staticResource( customer?.image, 400, 400 ) } className='imgPopup'/>
            </div>
            <div className='column width-50 padding10'>
              <div className='row justify-between mb-20'>
                <div className='boldTextEdit'>Name</div>
                <div>{ customer?.username }</div>
              </div>
              <div className='row justify-between mb-20'>
                <div className='boldTextEdit'>E-mail</div>
                <div>{ customer?.email }</div>
              </div>
              <div className='row justify-between mb-20'>
                <div className='boldTextEdit'>Phone Number</div>
                <div>{ customer?.telephone }</div>
              </div>
              <div className='row justify-between mb-20'>
                <div className='boldTextEdit'>Rating</div>
                <Rating
                  fillColor={ '#ff9811' }
                  emptyColor={ '#fff' }
                  size={ 25 }
                  initialValue={ customer?.rating }
                  readonly
                />
              </div>
              <div className='row justify-between'>
                <div className='boldTextEdit'>Status</div>
                { customer?.status ?
                  <div className='greenBtn'>active</div>
                  :
                  <div className='redBtn'>in active</div>
                }
              </div>
            </div>
          </div>
          <div className='row border-bottom'>
            <div className='width-30 padding10 boldTextEdit borderRight'>Country</div>
            <div className='width-50 padding10'>{ customer?.country }</div>
          </div>
          <div className='row'>
            <div className='width-30 padding10 boldTextEdit borderRight'>Registration Date</div>
            <div className='width-50 padding10'>
              <Moment format="MMM, DD YYYY">{ customer?.created_at }</Moment>
            </div>
          </div>
        </div>
        {/*<div className='d-flex justify-end padding20'>*/ }
        {/*  <Link to={ '/invoice' }>*/ }
        {/*    <button className='btnDetails' onClick={ () => hideProfileCustomer() }>View Order Details</button>*/ }
        {/*  </Link>*/ }
        {/*</div>*/ }
      </div>
    </div>
  );
};

export default ProfileCustomer;