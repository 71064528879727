import React, { useMemo } from 'react';
import '../styles/heatView.scss';
import { CiLocationOn } from 'react-icons/ci';
import GMap, { IPoint } from '../components/map/GMap';
import { useQuery } from 'react-query';
import { IOrders } from '../types/orders';
import { getListOrders } from '../stores/orders';
import Loader from '../components/Loader';

const HeatView = () => {
  const {
    data: orders,
    isLoading
  } = useQuery<IOrders>( [ 'orders' ], () => getListOrders() );

  const points = useMemo( () => {
    const _points: IPoint[] = [];
    orders?.items.forEach( o => {
      o.questionnaires.forEach( q => {
        _points.push( {
          id: q.id,
          lat: q.lat,
          lon: q.lon,
          name: q.name
        } );
      } );
    } );

    return _points;
  }, [ orders?.items.length ] );

  if ( isLoading ) return <Loader/>;

  return (
    <div className='heatView'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Heat View</div>
        <div className='column containerIn mb-10'>
          <div className='d-flex greyTitle border-bottom'>
            <div className='row justify-center align-center width-10'>
              <CiLocationOn className='iconHeatView'/>
              <div className='textHeatView'>Location</div>
            </div>
          </div>
          {/*<div className='row padding10'>*/ }
          {/*  <button className='btnHeatView'>Toggle Heatmap</button>*/ }
          {/*  <button className='btnHeatView'>Change color</button>*/ }
          {/*  <button className='btnHeatView'>Change radius</button>*/ }
          {/*  <button className='btnHeatView'>Change opacity</button>*/ }
          {/*</div>*/ }
          <div className='containerMap'>
            <div className='googleMaps'>
              <GMap points={ points } heatmapLibrary={ true }/>
            </div>
          </div>
        </div>
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default HeatView;
