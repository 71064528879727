import React from 'react';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';


export interface ISelectFieldProps extends StateManagerProps {
  containerClassName?: string;
  label?: string;
}

const SelectField = ( { containerClassName, label, defaultValue, options, ...props }: ISelectFieldProps ) => {
  const defValue = options?.find( ( o: any ) => o.value === defaultValue );

  return (
    <div className={ containerClassName }>
      <div className='boldTextEdit mb-10'>
        { label }
        { props.required && <span className='red-star'>*</span> }
      </div>
      <div>
        <Select className='selector-large width-100 mb-20' { ...props } defaultValue={ defValue } options={ options }/>
      </div>
    </div>
  );
};

export default SelectField;
