import React, { useEffect, useState } from 'react';
import '../styles/verification.scss';
import { $verification, hideVerification } from '../stores/modalContainer';
import { FiXCircle } from 'react-icons/fi';
import { useStore } from 'effector-react';
import ReactPlayer from 'react-player';
import { getQuestionerID } from '../stores/questionnaires';
import { IQuestionerID } from '../types/questionnaires';
import { IBrothelID } from '../types/brothels';
import { getBrothelID } from '../stores/brothels';

const Verification = () => {
	
	const [ state, setState ] = useState<IQuestionerID | IBrothelID>();
	const verification = useStore( $verification );

	useEffect( () => {
		if ( verification?.type === 'questionnaire' ) {
			getQuestionerID( verification.id ).then( setState );
		}
		if ( verification?.type === 'questionnaire' ) {
			getBrothelID( verification.id ).then( setState );
		}
	}, [ verification?.id ] );

	//@ts-ignore
	verification.verificationVideo = 'https://www.youtube.com/watch?v=LXb3EKWsInQ';

	//react-player
	return (
			<div className='verification popup'>
				<div className='column container mb-20'>
					<div className='d-flex justify-end mb-20'>
						<button
								type='button'
								onClick={ () => hideVerification() }>
							<FiXCircle className='iconPopup'/>
						</button>
					</div>
					<div className='row justify-between mb-50'>
						<div className='width-46'>
							<img src={ state?.verification } className='profileID'/>
						</div>
						<div className='width-46'>
							{
								//@ts-ignore
									verification?.verificationVideo &&
									//@ts-ignore
									<ReactPlayer url={ verification.verificationVideo }/>
							}
						</div>
					</div>
					<div className='row width-15 justify-between'>
						<button className='btnEdit btnEditGreen'>ACCEPT</button>
						<button className='btnEdit btnEditRed'>DECLINE</button>
					</div>
				</div>
			</div>
	);
};

export default Verification;