import React from 'react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IOrdersStat } from '../../types/statistic';
import { momentjs } from '../../utils/date';
import { EOrderStatus } from '../../types/orders';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      ticks: {
        min: 0,
        max: 50,
        stepSize: 20,
      },
      title: {
        display: true,
        text: 'Total',
        color: 'black',
        font: {
          weight: 'bold',
          size: 14
        }
      }
    },
    x: {
      grid: {
        display: false
      },
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        padding: 20,
        usePointStyle: true,
        pointStyle: 'rect',
        font: {
          weight: 'bold',
          size: 12
        }
      }
    },
  },
};

interface IProps {
  orders: IOrdersStat[];
}

const OrdersStatistic = ( { orders }: IProps ) => {
  const data = {
    labels: orders.map( o => momentjs( o.date ).format( 'MMM' ) ),
    datasets: [
      {
        label: 'Finish',
        data: orders.map( o => o.counts.find( c => c.status === EOrderStatus.EXECUTED )?.count || 0 ),
        backgroundColor: 'rgb(0,157,255)',
      },
      {
        label: 'Cancelled',
        data: orders.map( o => o.counts.find( c => c.status === EOrderStatus.REFUNDED )?.count || 0 ),
        backgroundColor: 'rgb(255,0,0)',
      },
    ],
  };

  return (
    <div className='parent'>
      <Bar data={ data } options={ options }/>
    </div>
  );
};

export default OrdersStatistic;
