import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import { useStore } from 'effector-react';
import { $languages } from '../../stores/language';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { IAdvertisementID } from '../../types/advertisement';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: IAdvertisementID;
}

const AdvertisementForm = ( { save, item }: IProps ) => {
  const languages = useStore( $languages );
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form
      ref={ form }
      key={ item?.id }
    >
      <Tabs>
        <TabList>
          <div className='row'>
            { languages.map( language =>
              <Tab key={ language.id }>{ language.name }</Tab>
            ) }
          </div>
        </TabList>
        <div className='row mt-40 mb-10 border-top'>
          { languages.map( language =>
            <TabPanel key={ language.id } className='row mt-10 p-0'>
              <CustomForm
                key={ item?.id }
                item={ item }
                fields={ {
                  name: {
                    name: 'Advertisement Name',
                    inputName: `name.${ language.language }`,
                    type: 'input',
                    props: {
                      containerClassName: 'width-50',
                      required: true
                    }
                  },
                  title: {
                    name: 'Advertisement Title',
                    inputName: `title.${ language.language }`,
                    type: 'input',
                    props: {
                      containerClassName: 'width-50',
                      required: true
                    }
                  },
                  subTitle: {
                    name: 'Advertisement Sub Title',
                    inputName: `subTitle.${ language.language }`,
                    type: 'input',
                    props: {
                      containerClassName: 'width-50',
                      required: true
                    }
                  },
                } }/>
            </TabPanel>
          ) }
        </div>
      </Tabs>

      <CustomForm
        item={ item }
        fields={ {
          image: {
            name: 'Advertisement Image',
            type: 'image',
            props: {
              containerClassName: 'width-50',
            }
          },
          file: {
            name: 'Choose New Image',
            type: 'file',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          maxItems: {
            name: 'Maximum number of cards',
            inputName: 'maxItems',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          priceForWeek: {
            name: 'Price For Week',
            inputName: 'priceForWeek',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          priceForMonth: {
            name: 'PriceForMonth',
            inputName: 'priceForMonth',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          status: {
            name: 'Status',
            type: 'checkbox',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
        } }/>

      <ButtonsCustomForm onClick={ onClick } group={ 'questionnaires' }/>
    </form>
  );
};

export default AdvertisementForm;