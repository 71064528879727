import React from 'react';

import Table from '../../components/Table';
import ButtonSettings from '../../components/ButtonSettings';
import { showProfileQuestionnaire, showVerification } from '../../stores/modalContainer';
import { getListQuestionnaires } from '../../stores/questionnaires';
import { useQuery } from 'react-query';
import { IQuestionnaires } from '../../types/questionnaires';
import Loader from '../../components/Loader';
import { GoFile } from 'react-icons/go';
import StatusQueationnaire from '../../components/status/StatusQueationnaire';
import QuestionnaireFilter from '../../components/filter/QuestionnaireFilter';
import SelectorActions from '../../components/selectors/SelectorActions';
import { useFilter } from '../../stores/filter';
import { Rating } from 'react-simple-star-rating';

const Questionnaires = () => {
  const filter = useFilter();

  const {
    data: questionnaires,
    isLoading
  } = useQuery<IQuestionnaires>( [ 'questionnaires', filter.toString() ], () => getListQuestionnaires( filter ) );

  return (
    <div className='masseur'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Questionnaires</div>
        <QuestionnaireFilter/>
        <SelectorActions/>
        { isLoading ?
          <Loader position={ 'static' }/>

          :
          <Table
            items={ questionnaires?.items || [] }
            count={ questionnaires?.count }
            fields={ {
              name: 'Name',
              brothel: 'Company',
              created_at: 'Signup day',
              age: 'Age',
              address: 'Address',
              rating: 'Rating',
              balance: 'Balance',
              verification: 'View/Edit documents',
              status: 'Status',
              actions: 'Actions'
            } }
            headFieldsClassName={ {
              verification: 'thText-center',
              status: 'thText-center',
              actions: 'thText-center'
            } }
            bodyFieldsClassName={ {
              verification: 'thText-center',
              status: 'thText-center',
              actions: 'thText-center'
            } }
            columnBody={ {
              rating: ( item ) => <Rating
                fillColor={ '#ff9811' }
                emptyColor={ '#fff' }
                size={ 25 }
                initialValue={ item?.rating }
                readonly
              />,
              created_at: ( item ) =>
                <div>
                  {
                    item.created_at.substring( 0, 10 )
                  }
                </div>,
              name: ( item ) =>
                <button onClick={ () => showProfileQuestionnaire( item.id ) }
                        className='linkMassage'>
                  { item.name }
                </button>,
              balance: ( item ) =>
                <div>
                  <div className='amount'>{ item.balance }</div>
                  <button className='btnAddbalance mb-5'>Add balance</button>
                </div>,
              verification: ( item ) =>
                <button onClick={ () => showVerification( { id: item.id, type: 'questionnaire' } ) }>
                  <GoFile className='thIcon'/>
                </button>,
              status: ( item ) => <StatusQueationnaire status={ item.status }/>,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings itemId={ item.id }/>
                </div>
            } }/>
        }
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default Questionnaires;
