import React from 'react';

import Table from '../components/Table';
import { useQuery } from 'react-query';
import { ECustomerRole, ICustomers } from '../types/customers';
import { getListCustomers } from '../stores/customers';
import Loader from '../components/Loader';
import { showProfileCustomer } from '../stores/modalContainer';
import { FilterParams } from '../stores/filter';

const CustomerOutstandingReport = () => {
  const {
    data: customers,
    isLoading
  } = useQuery<ICustomers>( [ 'customers' ], () => getListCustomers( new FilterParams( { roleId: ECustomerRole.CLIENT } ) ) );
  if ( isLoading ) return <Loader/>;

  return (
    <div className='customerOutstandingReport'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Outstanding Amount Report</div>
        {/*<AdminFilter/>*/ }
        <Table
          items={ customers?.items || [] }
          count={ customers?.count }
          fields={ {
            username: 'Contact Details',
            balance: 'Outstanding Amount',
          } }
          headFieldsClassName={ {
            action: 'thText-center width-10',
            balance: 'width-15'
          } }
          bodyFieldsClassName={ {
            action: 'thText-center',
            balance: 'thText-right',
          } }
          columnBody={ {
            username: ( item ) =>
              <button onClick={ () => showProfileCustomer( item.id ) } className='linkMassage'>
                { item.username || item.email }
              </button>,
          } }
        />
      </div>
    </div>
  );
};

export default CustomerOutstandingReport;