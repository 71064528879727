import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getTagID, saveTags } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import TagForm from './TagForm';

const EditTag = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: tag, isLoading } = useQuery( [ 'tag', id ], () => getTagID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveTags( id, data );

    navigate( '/manageServices/tags' );
  };

  return (
    <div className='editTags'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit { tag?.name?.en }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/tags' ) }>BACK TO
            LISTING
          </button>
        </div>
        <TagForm save={ save } item={ tag }/>
      </div>
    </div>
  );
};

export default EditTag;
