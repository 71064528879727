import React from 'react';

import Table from '../components/Table';
import { useQuery } from 'react-query';
import { useFilter } from '../stores/filter';
import Loader from '../components/Loader';
import { getLabels } from '../stores/labels';
import ButtonSettings from '../components/ButtonSettings';
import LabelsFilter from '../components/filter/LabelsFilter';

const LanguageLabel = () => {
  const filter = useFilter();
  const { data: items, isLoading } = useQuery( [ 'labels', filter.toString() ], () => getLabels( filter ) );

  return (
    <div className='languageLabel'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Language Label</div>
        <LabelsFilter/>
        { isLoading ?
          <Loader/>
          :
          <Table
            items={ items?.items || [] }
            count={ items?.count }
            fields={ {
              code: 'Code',
              text: 'Value',
              actions: 'Edit'
            } }
            headFieldsClassName={ {
              actions: 'thText-center'
            } }
            bodyFieldsClassName={ {
              actions: 'thText-center'
            } }
            columnBody={ {
              text: ( item ) => item.text.en,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings itemId={ item.id }/>
                </div>
            } }
          />
        }
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default LanguageLabel;