import React from 'react';

import Table from '../components/Table';
import { useQuery } from 'react-query';
import Loader from '../components/Loader';
import { ECustomerRole, ICustomers } from '../types/customers';
import { getListCustomers } from '../stores/customers';
import { FilterParams } from '../stores/filter';
import { showProfileCustomer } from '../stores/modalContainer';

const QuestionerPaymentReport = () => {
  const {
    data: customers,
    isLoading
  } = useQuery<ICustomers>( [ 'customers' ], () => getListCustomers( new FilterParams( { roleId: ECustomerRole.MODEL } ) ) );
  if ( isLoading ) return <Loader/>;

  return (
    <div className='questionnairesPaymentReport'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Questionnaires Outstanding Report</div>
        {/*<div className='row width-100 justify-between mb-30'>*/ }
        {/*  <div className='h3 colorBlack'>Search by Date...</div>*/ }
        {/*  <button type='button' className='btnAdd width-23'>View Cancelled Orders Payment Report</button>*/ }
        {/*</div>*/ }
        {/*<SearchOrders/>*/ }
        <Table
          items={ customers?.items || [] }
          count={ customers?.count }
          fields={ {
            username: 'Contact Details',
            balance: 'Outstanding Amount',
          } }
          headFieldsClassName={ {
            action: 'thText-center width-10',
            balance: 'width-15'
          } }
          bodyFieldsClassName={ {
            action: 'thText-center',
            balance: 'thText-right',
          } }
          columnBody={ {
            username: ( item ) =>
              <button onClick={ () => showProfileCustomer( item.id ) } className='linkMassage'>
                { item.username || item.email }
              </button>,
          } }
        />
        {/*<Table*/ }
        {/*  items={ customers?.items || [] }*/ }
        {/*  count={ customers?.count }*/ }
        {/*  fields={ {*/ }
        {/*    name: 'Name',*/ }
        {/*    details: 'Bank Details',*/ }
        {/*    total: 'Total Fare',*/ }
        {/*    cashreceived: 'Cash Received',*/ }
        {/*    commission: 'Total Commission',*/ }
        {/*    amount: 'Total Amount',*/ }
        {/*    paymentstatus: 'Payment Status'*/ }
        {/*  } }*/ }
        {/*  headFieldsClassName={ {*/ }
        {/*    total: 'width-10',*/ }
        {/*    cashreceived: 'width-10',*/ }
        {/*    commission: 'width-10',*/ }
        {/*    amount: 'width-10',*/ }
        {/*  } }*/ }
        {/*  bodyFieldsClassName={ {*/ }
        {/*    total: 'thText-right',*/ }
        {/*    cashreceived: 'thText-right',*/ }
        {/*    commission: 'thText-right',*/ }
        {/*    amount: 'thText-right',*/ }
        {/*  } }*/ }
        {/*  columnBody={ {*/ }
        {/*    details: ( items ) =>*/ }
        {/*      <button className='btnAddbalance'>View details</button>*/ }
        {/*  } }*/ }
        {/*/>*/ }
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default QuestionerPaymentReport;
