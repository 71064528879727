import { apiGet } from '../api';
import { FilterParams } from './filter';
import { IAlerts } from '../types/alerts';

export const getAlerts = async ( filter?: FilterParams ) => {
  return await apiGet<IAlerts>( {
    url: `/orders/alerts?${ filter?.toQueryParams() }`
  } );
};

export const blockOwner = async ( id: string ) => {
  // return await apiPost<IAlertID>( {
  //   url: `/orders/alerts/block/${ id }`,
  // } );
};