import React from 'react';
import { useNavigate } from 'react-router-dom';
import SmsTemplateForm from './SmsTemplateForm';
import { createAdvertisement } from '../../stores/advertisement';

const CreateSmsTemplate = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createAdvertisement( data );
  };

  return (
    <div className='editEmailTemplate'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit SMS Template</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/settings/smsTemplates' ) }>BACK TO
            LISTING
          </button>
        </div>
        <SmsTemplateForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateSmsTemplate;