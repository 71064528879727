import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SmsTemplateForm from './SmsTemplateForm';
import { useQuery } from 'react-query';
import { getSmsTemplate, saveSmsTemplate } from '../../stores/notifications';
import Loader from '../../components/Loader';

const EditSmsTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: smsTemplate, isLoading } = useQuery( [ 'smsTemplate', id ], () => getSmsTemplate( id || '' ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveSmsTemplate( id, data );

    navigate( '/settings/smsTemplates' );
  };

  return (
    <div className='editEmailTemplate'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit SMS Template</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/settings/smsTemplates' ) }>BACK TO
            LISTING
          </button>
        </div>
        <SmsTemplateForm save={ save } item={ smsTemplate }/>
      </div>
    </div>
  );
};

export default EditSmsTemplate;