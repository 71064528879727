import React from 'react';

import Table from '../../components/Table';
import ButtonSettings from '../../components/ButtonSettings';
import { useQuery } from 'react-query';
import { getSmsTemplates } from '../../stores/notifications';

const SmsTemplates = () => {
  const { data: items, isLoading } = useQuery( [ 'smsTemplates' ], () => getSmsTemplates() );

  return (
    <div className='smsTemplates'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Sms Templates</div>
        {/*<AdminFilter/>*/ }
        <Table
          items={ items?.items || [] }
          fields={ {
            code: 'Code',
            body: 'Body',
            action: ''
          } }
          headFieldsClassName={ {
            action: 'thText-center'
          } }
          bodyFieldsClassName={ {
            action: 'thText-center'
          } }
          columnBody={ {
            action: ( item ) =>
              <div className='padding5'>
                <ButtonSettings itemId={ item.id }/>
              </div>
          } }
        />
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default SmsTemplates;