import EchoClient from 'laravel-echo';
import { IAccountResponse } from '../types/auth';
import { getSubscriptions } from '../stores/chats';
import { getDomainUrl } from '../api/helpers';
import { getAuthToken } from '../stores/auth';

//@ts-ignore
// eslint-disable-next-line no-undef
window.io = require( 'socket.io-client' );

export const initializeEcho = ( account: IAccountResponse ) => {
  const Echo = new EchoClient( {
    namespace: '',
    broadcaster: 'socket.io',
    host: `${ getDomainUrl() }:6001`,
    auth:
      {
        headers:
          {
            'Authorization': 'Bearer ' + getAuthToken()
          }
      }
  } );

  Echo.private( `events.${ account.id }` )
    .listen( '.chat.update', ( e: any ) => {
      getSubscriptions().map( callback => callback( e.message ) );
    } );
};
