import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from '../../components/Loader';
import LanguageForm from './LanguageForm';
import { getLanguage, saveLanguage } from '../../stores/language';

const EditLanguage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: language, isLoading } = useQuery( [ 'language', id ], () => getLanguage( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveLanguage( id, data );

    navigate( '/settings/languages' );
  };

  return (
    <div className='editState'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Language</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/settings/languages' ) }>BACK TO
            LISTING
          </button>
        </div>
        <LanguageForm save={ save } item={ language }/>
      </div>
    </div>
  );
};

export default EditLanguage;