import { createEvent, createStore } from 'effector';

export const showProfileCustomer = createEvent<string | number>();
export const hideProfileCustomer = createEvent();

export const $profileCustomer = createStore<string | number | null>( null )
		.on( showProfileCustomer, ( state, newState ) => newState )
		.on( hideProfileCustomer, () => null );

export const showProfileQuestionnaire = createEvent<string | number>();
export const hideProfileQuestionnaire = createEvent();

export const $ProfileQuestionnaire = createStore<string | number | null>( null )
		.on( showProfileQuestionnaire, ( state, newState ) => newState )
		.on( hideProfileQuestionnaire, () => null );

export const showModalMessage = createEvent<number | string>();
export const hideModalMessage = createEvent();

export const $modalMessage = createStore<number | string | null>( null )
		.on( showModalMessage, ( state, newState ) => newState )
		.on( hideModalMessage, () => null );

interface IVerificationModal {
	id: string,
	type: 'brothel' | 'questionnaire'
}

export const showVerification = createEvent<IVerificationModal>();
export const hideVerification = createEvent();

export const $verification = createStore<IVerificationModal | null>( null )
		.on( showVerification, ( state, newState ) => newState )
		.on( hideVerification, () => null );