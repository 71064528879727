import {
  GoChecklist,
  GoColumns,
  GoFileMedia,
  GoGear,
  GoOrganization,
  GoPasskeyFill,
  GoPeople,
  GoPerson,
  GoSync,
  GoTasklist
} from 'react-icons/go';
import {
  HiArrowRightOnRectangle,
  HiChartBar,
  HiMap,
  HiOutlineChatBubbleOvalLeftEllipsis,
  HiOutlineGlobeAlt,
  HiOutlineMap,
  HiOutlineWrenchScrewdriver,
  HiOutlineXCircle
} from 'react-icons/hi2';
import React from 'react';
import Dashboard from '../pages/dashboard/Dashboard';
import Admin from '../pages/admin/Admin';
import AdminGroups from '../pages/admin/AdminGroups';
import { IRoute } from '../types/routes';
import Brothels from '../pages/brothels/Brothels';
import Questionnaires from '../pages/questionnaires/Questionnaires';
import Orders from '../pages/Orders';
import GodsView from '../pages/GodsView';
import HeatView from '../pages/HeatView';
import Reviews from '../pages/Reviews';
import Advertisement from '../pages/promoBanners/Advertisement';
import AlertQuestionnaires from '../pages/AlertQuestionnaires';
import Referral from '../pages/Referral';
import QuestionerPaymentReport from '../pages/QuestionerPaymentReport';
import CustomerOutstandingReport from '../pages/CustomerOutstandingReport';
import PaytmentSupportRequests from '../pages/PaytmentSupportRequests';
import WithdrawRequests from '../pages/WithdrawRequests';
import General from '../pages/settings/General';
import SMSTemplates from '../pages//smsTemplates/SMSTemplates';
import LanguageLabel from '../pages/LanguageLabel';
import Languages from '../pages/languages/Languages';
import Countries from '../pages/geo/Countries';
import Cities from '../pages/geo/Cities';
import PagesUtility from '../pages/PagesUtility';
import News from '../pages/News';
import Newsletter from '../pages/Newsletter';
import FAQ from '../pages/FAQ';
import FAQCategories from '../pages/FAQCategories';
import Logout from '../pages/Logout';
import Customers from '../pages/customers/Customers';
import Invoice from '../pages/editPage/Invoice';
import EditAdmin from '../pages/admin/EditAdmin';
import EditBrothels from '../pages/brothels/EditBrothels';
import EditQuestionnaires from '../pages/questionnaires/EditQuestionnaires';
import EditCustomer from '../pages/customers/EditCustomer';
import EditAdvertisement from '../pages/promoBanners/EditAdvertisement';
import EditSmsTemplate from '../pages/smsTemplates/EditSmsTemplate';
import EditLanguageLabel from '../pages/editPage/EditLanguageLabel';
import EditStaticPage from '../pages/editPage/EditStaticPage';
import EditHomePage from '../pages/editPage/EditHomePage';
import EditNews from '../pages/editPage/EditNews';
import EditFAQ from '../pages/editPage/EditFAQ';
import EditFAQCategories from '../pages/editPage/EditFAQCategories';
import EditCountry from '../pages/geo/EditCountry';
import EditCity from '../pages/geo/EditCity';
import TagsList from '../pages/manageServices/TagsList';
import EditTag from '../pages/manageServices/EditTag';
import CategoriesList from '../pages/manageServices/CategoriesList';
import EditCategory from '../pages/manageServices/EditCategory';
import AttributeGroups from '../pages/manageServices/AttributeGroups';
import EditAttributeGroups from '../pages/manageServices/EditAttributeGroups';
import AttributeList from '../pages/manageServices/AttributeList';
import EditAttribute from '../pages/manageServices/EditAttribute';
import EditAdminGroups from '../pages/admin/EditAdminGroups';
import CreateAdmin from '../pages/admin/CreateAdmin';
import CreateAdminGroups from '../pages/admin/CreateAdminGroups';
import CreateBrothels from '../pages/brothels/CreateBrothels';
import CreateQuestionnaires from '../pages/questionnaires/CreateQuestionnaires';
import CreateTag from '../pages/manageServices/CreateTag';
import CreateCategory from '../pages/manageServices/CreateCategory';
import CreateAttributeGroups from '../pages/manageServices/CreateAttributeGroups';
import CreateAttribute from '../pages/manageServices/CreateAttribute';
import CreateCustomers from '../pages/customers/CreateCustomers';
import CreateAdvertisement from '../pages/promoBanners/CreateAdvertisement';
import Regions from '../pages/geo/Regions';
import EditRegion from '../pages/geo/EditRegion';
import Areas from '../pages/geo/Areas';
import EditArea from '../pages/geo/EditArea';
import CreateLanguage from '../pages/languages/CreateLanguage';
import EditLanguage from '../pages/languages/EditLanguage';
import CreateSmsTemplate from '../pages/smsTemplates/CreateSmsTemplate';


export const routes: IRoute[] = [
  {
    name: 'Dashboard',
    icon: <GoColumns className='iconSideMenu'/>,
    path: '/',
    content: <Dashboard/>,
  },

  {
    name: 'Admin',
    icon: <GoPasskeyFill className='iconSideMenu'/>,
    path: '/admin',
    permissions: [ 'users:list', 'roles:list' ],
    children: [
      {
        name: 'Admin',
        path: '/list',
        content: <Admin/>,
        permissions: 'users:list',
      },
      {
        path: '/list/:id',
        content: <EditAdmin/>,
      },
      {
        path: '/list/create_admin',
        content: <CreateAdmin/>,
      },
      {
        name: 'Admin Groups',
        path: '/roles',
        content: <AdminGroups/>,
        permissions: 'roles:list',
      },
      {
        path: '/roles/:id',
        content: <EditAdminGroups/>
      },
      {
        path: '/roles/create_admin_group',
        content: <CreateAdminGroups/>
      }
    ]
  },
  {
    name: 'Brothels',
    icon: <GoOrganization className='iconSideMenu'/>,
    path: '/brothels',
    content: <Brothels/>,
    permissions: 'brothels:list',
  },

  {
    path: '/brothels/:id',
    content: <EditBrothels/>,
  },
  {
    path: '/brothels/create_brothels',
    content: <CreateBrothels/>,
  },

  {
    name: 'Questionnaires',
    icon: <GoPeople className='iconSideMenu'/>,
    path: '/questionnaires',
    content: <Questionnaires/>,
    permissions: 'questionnaires:list',
  },
  {
    path: '/questionnaires/:id',
    content: <EditQuestionnaires/>,
  },
  {
    path: '/questionnaires/create_questionnaires',
    content: <CreateQuestionnaires/>,
  },

  {
    name: 'Manage Services',
    icon: <GoTasklist className='iconSideMenu'/>,
    path: '/manageServices',
    permissions: 'manageServices:list',
    children: [
      {
        name: 'Tags',
        path: '/tags',
        content: <TagsList/>,
      },
      {
        path: '/tags/:id',
        content: <EditTag/>,
      },
      {
        path: '/tags/create_tags',
        content: <CreateTag/>,
      },
      {
        name: 'Categories',
        path: '/categories',
        content: <CategoriesList/>,
      },
      {
        path: '/categories/:id',
        content: <EditCategory/>
      },
      {
        path: '/categories/create_categories',
        content: <CreateCategory/>
      },
      {
        name: 'AttributeGroups',
        path: '/attribute_groups',
        content: <AttributeGroups/>,
      },
      {
        path: '/attribute_groups/:id',
        content: <EditAttributeGroups/>
      },
      {
        path: '/attribute_groups/create_attribute_groups',
        content: <CreateAttributeGroups/>
      },
      {
        name: 'Attributes',
        path: '/attributes',
        content: <AttributeList/>,
      },
      {
        path: '/attributes/:id',
        content: <EditAttribute/>
      },
      {
        path: '/attributes/create_attributes',
        content: <CreateAttribute/>
      }
    ]
  },

  {
    name: 'Customers',
    icon: <GoPerson className='iconSideMenu'/>,
    path: '/customers',
    content: <Customers/>,
    permissions: 'customers:list',
  },

  {
    path: '/customers/:id',
    content: <EditCustomer/>
  },

  {
    path: '/customers/create_customers',
    content: <CreateCustomers/>
  },

  {
    name: 'Orders',
    icon: <GoChecklist className='iconSideMenu'/>,
    path: '/orders',
    content: <Orders/>,
    permissions: 'orders:list',
  },

  {
    path: '/orders/:id',
    content: <Invoice/>,
  },

  // {
  //   name: 'PromoCode',
  //   icon: <HiOutlineReceiptPercent className='iconSideMenu'/>,
  //   path: '/promocode',
  //   content: <PromoCode/>,
  //   permissions: {
  //     group: [ 'promo' ],
  //     action: 'list',
  //   },
  // },
  //
  // {
  //   path: '/promocode/:id',
  //   content: <EditPromoCode/>
  // },
  //
  // {
  //   path: '/promocode/create_promo_code',
  //   content: <CreatePromoCode/>
  // },

  {
    name: 'Gods View',
    icon: <HiOutlineMap className='iconSideMenu'/>,
    path: '/gods_view',
    content: <GodsView/>,
    permissions: 'orders:list',
  },

  {
    name: 'Heat View',
    icon: <HiMap className='iconSideMenu'/>,
    path: '/heat_view',
    content: <HeatView/>,
    permissions: 'orders:list',
  },

  {
    name: 'Reviews',
    icon: <HiOutlineChatBubbleOvalLeftEllipsis className='iconSideMenu'/>,
    path: '/reviews',
    params: { type: 'questionnaire' },
    content: <Reviews/>,
    permissions: 'reviews:list',
  },

  {
    name: 'Advertisement Banners',
    icon: <GoFileMedia className='iconSideMenu'/>,
    path: '/advertisement',
    content: <Advertisement/>,
    permissions: 'promoModules:list',
  },

  {
    path: '/advertisement/:id',
    content: <EditAdvertisement/>
  },
  {
    path: '/advertisement/create_advertisement',
    content: <CreateAdvertisement/>
  },

  {
    name: 'Decline Alert',
    icon: <HiOutlineXCircle className='iconSideMenu'/>,
    path: '/decline_alert',
    permissions: 'orders:list',
    children: [
      {
        name: 'Alert for Questionnaires',
        path: '/alert_for_questionnaires',
        content: <AlertQuestionnaires/>,
      },
      // {
      //   name: 'Alert for Customers',
      //   path: '/alert_for_customers',
      //   content: <AlertCustomers/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
    ]
  },

  {
    name: 'Reports',
    icon: <HiChartBar className='iconSideMenu'/>,
    path: '/reports',
    permissions: 'customers:list',
    children: [
      // {
      //   name: 'Payment Report',
      //   path: '/payment',
      //   content: <Payment/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      {
        name: 'Referral Report',
        path: '/referral',
        content: <Referral/>,
      },
      /*      {
              name: 'Wallet Report',
              path: '/wallet',
              content: <Wallet/>,
              permissions: {
                group: 'users',
                action: 'list',
              },
            },*/
      {
        name: 'Questionnaire Outstanding Report',
        path: '/questioner_payment_report',
        content: <QuestionerPaymentReport/>,
      },
      {
        name: 'Customer Outstanding Report',
        path: '/customer_outstanding_report',
        content: <CustomerOutstandingReport/>,
      },
    ]
  },

  {
    name: 'Support Requests',
    icon: <GoSync className='iconSideMenu'/>,
    path: '/support',
    permissions: [ 'withdrawRequests:list', 'users:list' ],
    children: [
      // {
      //   name: 'Contact Us',
      //   path: '/contact_us',
      //   content: <ContactUs/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      // {
      //   name: 'SOS',
      //   path: '/sos',
      //   content: <Sos/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      // {
      //   name: 'Orders Help',
      //   path: '/orders_help',
      //   content: <OrdersHelp/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      {
        name: 'Payment Requests',
        path: '/payment_requests',
        content: <PaytmentSupportRequests/>,
        permissions: 'users:list',
      },
      {
        name: 'Withdraw Requests',
        path: '/withdrawRequests',
        content: <WithdrawRequests/>,
        permissions: 'withdrawRequests:list',
      },
    ]
  },

  {
    name: 'Settings',
    icon: <GoGear className='iconSideMenu'/>,
    path: '/settings',
    permissions: 'settings:list',
    children: [
      {
        name: 'General',
        path: '/general',
        content: <General/>,
        permissions: 'settings:list',
      },
      // {
      //   name: 'Email Templates',
      //   path: '/email_templates',
      //   content: <EmailTemplates/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      {
        name: 'SMS Templates',
        path: '/smsTemplates',
        content: <SMSTemplates/>,
        permissions: 'smsTemplates:list',
      },
      {
        path: '/smsTemplates/create',
        content: <CreateSmsTemplate/>
      },
      {
        path: '/smsTemplates/:id',
        content: <EditSmsTemplate/>
      },
      {
        name: 'Language Labels',
        path: '/language_labels',
        content: <LanguageLabel/>,
        permissions: 'labels:list',
      },
      {
        name: 'Language',
        path: '/languages',
        content: <Languages/>,
        permissions: 'languages:list',
      },
      {
        path: '/languages/create',
        content: <CreateLanguage/>,
      },
      {
        path: '/languages/:id',
        content: <EditLanguage/>,
      },
    ]
  },
  {
    name: 'Localization',
    icon: <HiOutlineGlobeAlt className='iconSideMenu'/>,
    path: '/localization',
    permissions: 'geo:list',
    children: [
      {
        name: 'Country',
        path: '/countries',
        content: <Countries/>,
      },
      {
        path: '/countries/:id',
        content: <EditCountry/>
      },
      {
        name: 'Region',
        path: '/regions',
        content: <Regions/>,
      },
      {
        path: '/regions/:id',
        content: <EditRegion/>
      },
      {
        name: 'Area',
        path: '/areas',
        content: <Areas/>,
      },
      {
        path: '/areas/:id',
        content: <EditArea/>
      },
      {
        name: 'State',
        path: '/cities',
        content: <Cities/>,
      },
      {
        path: '/cities/:id',
        content: <EditCity/>
      }
    ]
  },

  {
    name: 'Utility',
    icon: <HiOutlineWrenchScrewdriver className='iconSideMenu'/>,
    path: '/utility',
    permissions: [ 'pages:list', 'news:list' ],
    children: [
      {
        name: 'Pages',
        path: '/pages',
        content: <PagesUtility/>,
        permissions: 'pages:list',
      },
      // {
      //   name: 'Home Page',
      //   path: '/home_page',
      //   content: <HomePage/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      {
        name: 'News',
        path: '/news',
        content: <News/>,
        permissions: 'news:list',
      },
      {
        path: '/news/:id',
        content: <EditNews/>,
      },
      {
        name: 'Newsletter Subscribers',
        path: '/newsletter',
        content: <Newsletter/>,
        permissions: 'news:list',
      },
      {
        name: 'FAQ',
        path: '/faq',
        content: <FAQ/>,
        permissions: 'faq:list',
      },
      {
        path: '/faq/:id',
        content: <EditFAQ/>
      },
      {
        name: 'FAQ Categories',
        path: '/faq_categories',
        content: <FAQCategories/>,
        permissions: 'faq:list',
      },
      {
        path: '/faq_categories/:id',
        content: <EditFAQCategories/>
      },
      // {
      //   name: 'Help Topics',
      //   path: '/help_topics',
      //   content: <HelpTopics/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      // {
      //   path: '/help_topics/:id',
      //   content: <EditHelpTopics/>
      // },
      // {
      //   name: 'Help Topics Categories',
      //   path: '/help_topics_categories',
      //   content: <HelpTopicsCategories/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      // {
      //   path: '/help_topics_categories/:id',
      //   content: <EditHelpTopicsCategories/>
      // },
      // {
      //   name: 'Cancel Reason',
      //   path: '/cancel_reason',
      //   content: <CancelReason/>,
      //   permissions: {
      //     group: 'users',
      //     action: 'list',
      //   },
      // },
      // {
      //   path: '/cancel_reason/:id',
      //   content: <EditCancelReason/>
      // },
      // {
      //   name: 'Send Push Notification',
      //   path: '/send_push_notification',
      //   content: <SendPushNotification/>,
      // },
      // {
      //   name: 'System Diagnostic', // TODO это нужно?
      //   path: '/diagnostic',
      //   content: <SystemDiagnostic/>,
      //
      // },
      // {
      //   name: 'App Launch Info', // это нужно?
      //   path: '/app_info',
      //   content: <AppLaunchInfo/>,
      // },
    ]
  },

  {
    name: 'Logout',
    icon: <HiArrowRightOnRectangle className='iconSideMenu'/>,
    path: '/logout',
    content: <Logout/>,
  },

  // {
  //   path: '/edit_email_template',
  //   content: <EditEmailTemplate/>
  // },
  // {
  //   path: '/edit_sms_template',
  //   content: <EditSmsTemplate/>
  // },
  {
    path: '/edit_language_label',
    content: <EditLanguageLabel/>
  },

  {
    path: '/edit_static_page',
    content: <EditStaticPage/>
  },

  {
    path: '/edit_home_page',
    content: <EditHomePage/>
  },

];

