import { IPriceBrothel } from './prices';
import { IQuestionnaires } from './questionnaires';
import { IReviews } from './reviews';

export enum EBrothelStatus {
  CREATED,
  CONFIRMED,
  REJECTED
}

export interface IBrothels {
  items: IBrothel[];
  count: number;
}

export interface IBrothel {
  id: string;
  customerId: string;
  cityId: number;
  address: string;
  lat: string;
  lon: string;
  rating: number;
  name: string;
  about: string;
  telephone: string;
  photo: string;
  video: string;
  status: EBrothelStatus;
  verification: string;
  prices: IPriceBrothel[];

}

export interface IBrothelID extends IBrothel {
  owner: string;
  questionnaires: IQuestionnaires[];
  reviewers: IReviews[];
}

