import { FilterParams } from './filter';
import { apiGet, apiPost } from '../api';
import { ILabel, ILabels } from '../types/labels';

export const getLabels = async ( filter?: FilterParams ) => {
  return await apiGet<ILabels>( { url: `/labels?${ filter?.toQueryParams() }` } );
};

export const getLabel = async ( id: string | number ) => {
  return await apiGet<ILabel>( { url: `/labels/${ id }` } );
};

export const saveLabel = async ( id: string, data: any ) => {
  return await apiPost<ILabel>( {
    url: `/labels/${ id }`,
    postData: data
  } );
};
