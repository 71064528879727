import React from 'react';
import SelectField, { ISelectFieldProps } from './SelectField';

interface IProps extends ISelectFieldProps {

}

const options = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' },
];

const GenderField = ( props: IProps ) => {
  return (
    <SelectField { ...props } options={ options }/>
  );
};

export default GenderField;
