import React from 'react';
import { useStore } from 'effector-react';
import { $modalMessage, $profileCustomer, $ProfileQuestionnaire, $verification } from '../stores/modalContainer';
import ProfileCustomer from './popup/ProfileCustomer';
import PopUpMessage from './popup/PopUpMessage';
import Verification from './Verification';
import ProfileQuestionnaire from './popup/ProfileQuestionnaire';

const ModalContainer = () => {

	const visibleProfileCustomer = useStore( $profileCustomer );

	const visibleProfileQuestionnaire = useStore( $ProfileQuestionnaire );

	const visibleModalMessage = useStore( $modalMessage );

	const visibleVerification = useStore( $verification );

	return (
			<div>
				{ visibleProfileCustomer &&
						<ProfileCustomer/>
				}

				{ visibleProfileQuestionnaire &&
						<ProfileQuestionnaire/>
				}

				{
						visibleModalMessage &&
						<PopUpMessage/>
				}

				{
						visibleVerification &&
						<Verification/>
				}

			</div>
	);
};

export default ModalContainer;