import React from 'react';
import '../../styles/general.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CustomForm from '../../components/form/CustomForm';
import Access from '../../components/access/Access';
import { useQuery } from 'react-query';
import { getSettings, saveSettings } from '../../stores/settings';

const General = ( { group }: any ) => {
  const { data: settings } = useQuery( [ 'settings' ], getSettings );

  const save = async ( e: any ) => {
    e.preventDefault();
    const data = new FormData( e.target );
    await saveSettings( data );
  };

  return (
    <div className='general'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>General</div>
        <div className='column containerIn mb-10'>
          <div className='d-flex greyTitle colorBlack border-bottom mb-5'>General Settings</div>
          <form onSubmit={ save } className='column padding10'>
            <Tabs>
              <TabList>
                <div className='row absolut'>
                  <Tab>General</Tab>
                  {/*<Tab>E-mail</Tab>*/ }
                  {/*<Tab>Appearance</Tab>*/ }
                  <Tab>SMS</Tab>
                  {/*<Tab>App Settings</Tab>*/ }
                  <Tab>Payment</Tab>
                </div>
              </TabList>
              <div className='row mt-40 mb-10 border-top'>
                <TabPanel className='row'>
                  <CustomForm
                    item={ settings }
                    fields={ {
                      commission_percentage: {
                        name: 'Commission Percentage',
                        type: 'input',
                        props: {
                          containerClassName: 'width-80'
                        }
                      },
                    } }
                  />
                </TabPanel>
                <TabPanel className='row'>
                  <CustomForm
                    item={ settings }
                    fields={ {
                      sendpulse_user_id: {
                        name: 'SendPulse user id',
                        type: 'input',
                        props: {
                          containerClassName: 'width-80'
                        }
                      },
                      sendpulse_api_secret: {
                        name: 'SendPulse api secret',
                        type: 'input',
                        props: {
                          containerClassName: 'width-80'
                        }
                      },
                      sendpulse_sender_name: {
                        name: 'SendPulse sender name',
                        type: 'input',
                        props: {
                          containerClassName: 'width-80'
                        }
                      },
                    } }
                  />
                </TabPanel>
                <TabPanel className='row'>
                 

                </TabPanel>
              </div>
            </Tabs>
            <Access group={ 'general' } action='save'>
              <button className='btnSettings1'>SAVE CHANGES</button>
            </Access>
          </form>
        </div>
      </div>
    </div>
  );
};

export default General;