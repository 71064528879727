import { apiGet, apiPost } from '../api';
import { IAdvertisementID, IAdvertisements } from '../types/advertisement';
import { FilterParams } from './filter';


export const getListAdvertisements = async ( filter?: FilterParams ) => {
  return await apiGet<IAdvertisements>( { url: `/promoModules?${ filter?.toQueryParams() }` } );
};

export const getAdvertisementID = async ( id?: string ) => {
  return await apiGet<IAdvertisementID>( { url: `/promoModules/${ id }` } );
};

export const saveAdvertisement = async ( id: string, data: any ) => {
  return await apiPost<IAdvertisementID>( {
    url: `/promoModules/${ id }`,
    postData: data
  } );
};

export const createAdvertisement = async ( data: any ) => {
  return await apiPost<IAdvertisementID>( {
    url: '/promoModules',
    postData: data
  } );
};
