import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAdvertisement } from '../../stores/advertisement';
import AdvertisementForm from './AdvertisementForm';

const CreateAdvertisement = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createAdvertisement( data );
  };

  return (
    <div className='editAdvertisement'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Banner</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/advertisement' ) }>BACK TO LISTING
          </button>
        </div>
        <AdvertisementForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateAdvertisement;