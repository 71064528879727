import React from 'react';
import ReactPaginate from 'react-paginate';
import '../styles/paginage.scss';
import { useFilterParams } from '../stores/filter';

interface IProps {
  limit: number;
  count: number;
  onChange?: ( value: number ) => void;
}

const Paginate = ( { count, limit, onChange }: IProps ) => {
  const [ filter, changeFilter ] = useFilterParams();

  const change = ( value: number ) => {
    if ( onChange ) {
      onChange( value );
    } else {
      changeFilter( 'page' )( { value: value } );
    }
  };

  if ( count / limit <= 1 ) {
    return <></>;
  }

  return (
    <div className="custom-paginate">
      <ReactPaginate
        initialPage={ Number( filter.page || '1' ) - 1 }
        previousLabel="<"
        nextLabel=">"
        pageCount={ count / limit }
        onPageChange={ ( e ) => change( e.selected + 1 ) }
      />
    </div>
  );
};

export default Paginate;