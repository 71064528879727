import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { ICustomerID } from '../../types/customers';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: ICustomerID;
}

const CustomerForm = ( { save, item }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <CustomForm
        key={ item?.id }
        item={ item }
        fields={ {
          username: {
            name: 'First Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          email: {
            name: 'E-mail',
            type: 'input',
            props: {
              containerClassName: 'width-50'
            }
          },
          pass: {
            name: 'Password',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          file: {
            name: 'Profile Picture',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20'
            }
          },
          country: {
            name: 'Country',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          telephone: {
            name: 'Phone',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          checkbox: {
            name: 'Status',
            type: 'checkbox',
          },
        } }
      />
      <ButtonsCustomForm onClick={ onClick } group={ 'customers' }/>
    </form>
  );
};

export default CustomerForm;