import React, { useState } from 'react';
import Select from 'react-select';
import CustomersQuestionnaires from '../../../components/charts/CustomersQuestionnaires';
import { useQuery } from 'react-query';
import { getClientsStat } from '../../../stores/statistic';
import Loader from '../../../components/Loader';
import { yearsList } from '../../../stores/data';

const ClientsChart = () => {
  const [ selectedOption, setSelectedOption ] = useState( yearsList[ 0 ] );
  const change = ( value: any ) => {
    setSelectedOption( value );
  };

  const {
    data: clients,
    isLoading
  } = useQuery( [ 'clients_stat', selectedOption.value ], () => getClientsStat( selectedOption.value ) );


  return (
    <div className='column containerContent width-100'>
      { isLoading ?
        <Loader position={ 'static' }/>
        :
        <>
          <div className='row justify-between width-90 padding20 mb-30'>
            <div className='titleDashboardPage'>Customer/Questionnaires</div>
            <div className='row justify-between align-center width-10'>
              <div className='text-in-Title'>Year</div>
              <Select
                className='selector-small width-70'
                defaultValue={ selectedOption }
                onChange={ change }
                options={ yearsList }
              />
            </div>
          </div>
          <div className='mb-20'>
            <CustomersQuestionnaires clients={ clients || [] }/>
          </div>
        </>
      }
    </div>
  );
};

export default ClientsChart;
