import { apiDelete, apiGet, apiPost } from '../api';
import { EAdminStatus, IAdminID, IAdmins } from '../types/admins';
import { FilterParams } from './filter';

export const getListAdmins = async ( filter?: FilterParams ) => {
  return await apiGet<IAdmins>( { url: `/users?${ filter?.toQueryParams() }` } );
};

export const getAdminID = async ( id?: string ) => {
  return await apiGet<IAdminID>( { url: `/users/${ id }`, } );
};

export const deleteAdmin = async ( id: string | number ) => {
  return await apiDelete<{}>( { url: `/users/${ id }`, } );
};

export const activateAdmin = async ( id: string | number ) => {
  return await apiPost<IAdminID>( {
    url: `/users/${ id }`,
    postData: {
      status: EAdminStatus.ACTIVE
    }
  } );
};

export const deactivateAdmin = async ( id: string | number ) => {
  return await apiPost<IAdminID>( {
    url: `/users/${ id }`,
    postData: {
      status: EAdminStatus.INACTIVE
    }
  } );
};

export const saveAdmin = async ( id: string, data: any ) => {
  return await apiPost<IAdminID>( {
    url: `/users/${ id }`,
    postData: data
  } );
};

export const createAdmin = async ( data: any ) => {
  return await apiPost<IAdminID>( {
    url: '/users',
    postData: data
  } );
};
