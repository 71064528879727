import React, { useMemo, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import mapIcon from '../../images/mapIcon.svg';
import '../../styles/googleMaps.scss';

const Marker = ( { children }: any ) => children;

export interface IPoint {
  id: string | number;
  lat: number;
  lon: number;
  name: string;
}

interface IProps {
  points: IPoint[];
  heatmapLibrary?: boolean;
}

const GMap = ( { points: _points, heatmapLibrary }: IProps ) => {
  const mapRef = useRef<any>( null );
  const [ bounds, setBounds ] = useState<number[]>( [] );
  const [ zoom, setZoom ] = useState( 10 );

  const points = useMemo( () => {
    return _points.map( point => ( {
      type: 'Feature',
      properties: { cluster: false, crimeId: point.id },
      geometry: {
        type: 'Point',
        coordinates: [
          point.lon,
          point.lat
        ]
      }
    } ) );
  }, [] );

  const { clusters, supercluster } = useSupercluster( {
    points: !heatmapLibrary ? points : [],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  } );

  const heatmap = heatmapLibrary ? {
    positions: _points.map( p => ( { lat: p.lat, lng: p.lon } ) ),
    options: {}
  } : undefined;

  console.log( clusters );

  return (
    <GoogleMapReact
      // @ts-ignore
      // eslint-disable-next-line no-undef
      bootstrapURLKeys={ { key: process.env.REACT_APP_GOOGLE_API_KEY } }
      defaultCenter={ { lat: _points[ 0 ]?.lat || 48.863050, lng: _points[ 0 ]?.lon || 2.326285 } }
      defaultZoom={ 10 }
      yesIWantToUseGoogleMapApiInternals
      heatmapLibrary={ heatmapLibrary }
      heatmap={ heatmap }
      onGoogleApiLoaded={ ( { map } ) => {
        mapRef.current = map;
      } }
      onChange={ ( { zoom, bounds } ) => {
        setZoom( zoom );
        setBounds( [
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ] );
      } }
    >
      { clusters.map( cluster => {
        const [ longitude, latitude ] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount
        } = cluster.properties;

        if ( isCluster ) {
          return (
            <Marker
              key={ `cluster-${ cluster.id }` }
              lat={ latitude }
              lng={ longitude }
            >
              <div
                className="align-center cluster-marker d-flex justify-center"
                style={ {
                  width: `${ 10 + ( pointCount / points.length ) * 20 }px`,
                  height: `${ 10 + ( pointCount / points.length ) * 20 }px`
                } }
                onClick={ () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom( cluster.id ),
                    20
                  );
                  mapRef.current.setZoom( expansionZoom );
                  mapRef.current.panTo( { lat: latitude, lng: longitude } );
                } }
              >
                { pointCount }
              </div>
            </Marker>
          );
        }

        return (
          <Marker
            key={ `crime-${ cluster.properties.crimeId }` }
            lat={ latitude }
            lng={ longitude }
          >
            <button className="crime-marker">
              <img src={ mapIcon } alt=""/>
            </button>
          </Marker>
        );
      } ) }
    </GoogleMapReact>
  );
};

export default GMap;
