import React from 'react';
import '../../styles/search.scss';
import { useFilterParams } from '../../stores/filter';

const LabelsFilter = () => {
  const [ filter, changeFilter, clearFilter ] = useFilterParams();

  return (
    <div className='search column justify-center'>
      <div className='row align-center width-100'>
        <div className='row width-50 align-center gap-10'>
          <div className='searchTitle'>Search</div>
          <input
            type='text'
            placeholder='Code'
            className='input width-20'
            defaultValue={ filter.code }
            onChange={ changeFilter( 'code' ) }
          />
          <input
            type='text'
            placeholder='Text'
            className='input width-20'
            defaultValue={ filter.text }
            onChange={ changeFilter( 'text' ) }
          />

          <button type='button' className='btn width-15' onClick={ clearFilter }>RESET</button>
        </div>
      </div>
    </div>
  );
};

export default LabelsFilter;