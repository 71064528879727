import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createBrothels } from '../../stores/brothels';
import BrothelForm from './BrothelForm';

const CreateBrothels = () => {
  const navigate = useNavigate();
  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form || undefined );

    await createBrothels( data );
  };

  return (
    <div className='editBrothels'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Brothel</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/brothels' ) }>BACK TO LISTING
          </button>
        </div>
        <BrothelForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateBrothels;
