import React from 'react';
import { GoBell } from 'react-icons/go';

const NotificationAlerts = () => {
  return (
    <div className='column containerContent width-100'>
      <div className='row justify-between width-90 padding20'>
        <div className='titleDashboardPage'>Notifications Alerts Panel</div>
        <button className='btnView width-10'>View all</button>
      </div>
      <button className='d-flex width-90 padding20'>
        <div className='row justify-between align-center padding10 width-100 bName'>
          <div className='row'>
            <div className='circleDiv purple d-flex mr-10'>
              <GoBell className='iconCircle dark-purple'/>
            </div>
            <div className='column align-center justify-center'>
              <div className='text-up'>Ahma*****</div>
              <div className='text-down'>s*******</div>
            </div>
          </div>
          <div className='d-flex text-up align-center justify-center'>63 Days ago</div>
        </div>
      </button>
    </div>
  );
};

export default NotificationAlerts;
