import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import { useStore } from 'effector-react';
import { $languages } from '../../stores/language';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { IAttributeGroupID } from '../../types/questionnaires';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: IAttributeGroupID;
}

const AttributeGroupForm = ( { save, item }: IProps ) => {
  const languages = useStore( $languages );
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <Tabs>
        <TabList>
          <div className='row'>
            { languages.map( language =>
              <Tab key={ language.id }>{ language.name }</Tab>
            ) }
          </div>
        </TabList>
        <div className='row mt-40 mb-10 border-top'>
          { languages.map( language =>
            <TabPanel key={ language.id } className='row mt-10'>
              <CustomForm
                key={ item?.id }
                item={ item }
                fields={ {
                  name: {
                    name: 'Attribute Group Name',
                    inputName: `name.${ language.language }`, //чтение нейма в котором содержится объект, его нельзя было прочесть, чтобы это сделать - куча строк в кастом форм
                    type: 'input',
                    props: {
                      containerClassName: 'width-50',
                      required: true
                    }
                  },
                } }/>
            </TabPanel>
          ) }
        </div>
      </Tabs>

      <ButtonsCustomForm onClick={ onClick } group={ 'questionnaires' }/>
    </form>
  );
};

export default AttributeGroupForm;