import { apiGet } from '../api';
import { IOrderID, IOrders } from '../types/orders';
import { FilterParams } from './filter';

export const getListOrders = async ( filter?: FilterParams ) => {
  return await apiGet<IOrders>( { url: '/orders?' + filter?.toQueryParams() } );
};

export const getOrderID = async ( id?: string | null | number ) => {
  return await apiGet<IOrderID>( { url: `/orders/${ id }` } );
};