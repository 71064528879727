import React, { RefObject } from 'react';

interface IProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  containerClassName?: string;
  label?: string;
  subTitle?: string;
  inputRef?: RefObject<HTMLTextAreaElement>
}

const TextareaField = ( { containerClassName, inputRef, label, subTitle, ...props }: IProps ) => {
  return (
    <div className={ containerClassName }>
      <div className='boldTextEdit mb-10'>
        { label }
        { props.required && <span className='red-star'>*</span> }
      </div>
      { subTitle &&
        <div className='boldTextEdit mb-10'>
          { subTitle }
        </div>
      }
      <div className='row justify-between'>
        <textarea ref={ inputRef } className='width-100 textarea' { ...props }/>
      </div>
    </div>
  );
};

export default TextareaField;
