import React from 'react';
import { CiLocationOn, CiStar } from 'react-icons/ci';
import '../../styles/invoice.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IOrderID, IProduct } from '../../types/orders';
import { getOrderID } from '../../stores/orders';
import Loader from '../../components/Loader';
import moment from 'moment';

const Invoice = () => {

	const { id } = useParams(); //все данные из юзе парамс - это стринга, потому что это адрес в браузере
	const navigate = useNavigate();

	const { data: orderID, isLoading } = useQuery<IOrderID>( [ 'orderID' ], () => getOrderID( id ) );
	if ( isLoading ) return <Loader/>;

	const products = orderID?.products?.sort( ( a, b ) => moment( a.arrivalTime ).unix() - moment( b.arrivalTime ).unix() ) || []; //это сортировка
	const firstProduct: undefined | IProduct = products[ 0 ];

	return (
			<div className='invoice'>
				<div className='column containerContent padding20'>
					<div className='row titleAdmin justify-between mb-10'>
						<div className='h2'>Invoice</div>
						<button className='btnAdd' type='button' onClick={ () => navigate( '/orders' ) }>CLOSE</button>
					</div>
					<div className='column containerInvoice'>
						<div className='orderData width-100 border-bottom mb-5'>
							<div className='row justify-between width-35'>
								<div className='boldTitle padding-left10'>Your order</div>
								<div className='thinTitle'>
									{/*moment не ставится */ }
									{ firstProduct?.arrivalTime }
								</div>
							</div>
						</div>
						<div className='row width-100 justify-between'>
							<div className='column width-46 padding10 ml-15 mt-5 mb-10 border-circle'>
								<div className='column width-100 border-bottom mt-5 mb-5'>
									<div className='row align-center justify-start'>
										<CiLocationOn className='iconInvoice'/>
										<div className='boldTitle padding-left10'>{ firstProduct?.arrivalTime }</div>
									</div>
									<div className='ml-30 thinTitle mb-5'>
										{ orderID?.deliveryAddress }
									</div>
								</div>
								<div className='column width-100 align-center justify-center border-bottom mt-5 mb-5'>
									<div className='thinTitle'>Service Type</div>
									{/*тут нормально время отобразится?*/ }
									<div className='boldTitle mb-10'>{ firstProduct?.time }</div>
								</div>
								<div className='row width-80 justify-between mt-5 mb-20'>
									<div className='column '>
										{ products.map( product =>
												<div key={ product.id } className=' row width-35 justify-between'>
													<img className='imgInvoice' src={ product.questionnaire.photo }/>
													<div className='column'>
														<div className='boldTitle'>Questionaire</div>
														<div className='thinTitle mb-20'>{ product.questionnaire.name }</div>
														<div className='boldTitle'>Rating</div>
														<div className='row justify-start align-center'>
															<CiStar className='star'/>
															<div className='thinTitle'>{ product.questionnaire.rating }</div>
														</div>
													</div>
												</div>
										) }

									</div>
									<div className='row width-35 justify-between'>
										<img className='imgInvoice' src={ orderID?.customer?.image }/>
										<div className='column'>
											<div className='boldTitle'>Customer</div>
											<div className='thinTitle mb-20'>{ orderID?.customer?.username }</div>
											<div className='boldTitle'>Rating</div>
											<div className='row justify-start align-center'>
												<CiStar className='star'/>
												<div className='thinTitle'>{ orderID?.customer?.rating }</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='column width-46 padding10 mr-30'>
								<div className='boldTitle d-flex align-center justify-center padding10 border-bottom mb-5'>
									Fare Breakdown For Job No :{ orderID?.id }
								</div>
								<div className='column width-100 border-bottom mt-5 mb-10'>
									<div className='row justify-between thinTitle mb-5'>
										<div>{ firstProduct?.time }</div>
										<div>{ firstProduct?.price }</div>
									</div>
								</div>
								<div className='column width-100'>
									<div className='row justify-between boldPrice mb-10'>
										<div>Total Fare (Via Cash)</div>
										<div>{ firstProduct?.total }</div>
									</div>
									<div className='border-dotted row justify-between boldPrice'>
										<div>Commission</div>
										<div>{ firstProduct?.commission }</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
};

export default Invoice;