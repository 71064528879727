import React from 'react';

import Table from '../../components/Table';
import ButtonSettings from '../../components/ButtonSettings';
import GeoFilter from '../../components/filter/GeoFilter';
import { useQuery } from 'react-query';
import { useFilter } from '../../stores/filter';
import { getCountries } from '../../stores/geo';
import Loader from '../../components/Loader';

const Countries = () => {
  const filter = useFilter();
  const { data: items, isLoading } = useQuery( [ 'countries', filter.toString() ], () => getCountries( filter ) );
  if ( isLoading ) return <Loader/>;

  return (
    <div className='country'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Country</div>
        <GeoFilter/>
        <Table
          items={ items?.items || [] }
          count={ items?.count }
          fields={ {
            // flag: 'Flag',
            name: 'Country',
            actions: 'Actions'
          } }
          bodyFieldsClassName={ {
            status: 'thText-center',
            actions: 'thText-center',
            flag: 'thText-center width-5'
          } }
          headFieldsClassName={ {
            status: 'thText-center',
            actions: 'thText-center',
            flag: 'thText-center width-5'
          } }
          columnBody={ {
            actions: ( item ) =>
              <div className='padding5'>
                <ButtonSettings itemId={ item.id }/>
              </div>
          } }/>
      </div>
    </div>
  );
};

export default Countries;