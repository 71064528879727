import { IQuestioner, IQuestionerID } from './questionnaires';
import { ICustomer } from './customers';

export enum EOrderStatus {
  CREATED = 0,
  PAID = 1,
  PAY_BLOCKED = 2,
  PART_REFUNDED = 3,
  REFUNDED = 4,
  EXECUTED = 5,
}

export interface IOrders {
  items: IOrder[];
  count: number;
}

//todo: сделать кастомеров и квестион сложными объектами
export interface IOrder {
  id: number;
  customer: ICustomer;
  questionnaires: IQuestioner[];
  deliveryAddress: string;
  status: EOrderStatus;
  statusName: string;
  total: number;
  created_at: string;
}

//todo: commission, invoiceId
export interface IOrderID extends IOrder {
  settle: boolean;
  invoiceId: number;
  products: IProduct[];
}

export interface IProduct {
  id: number;
  needDelivery: boolean;
  price: number;
  total: number;
  commission: number;
  time: string;
  arrivalTime: string;
  status: number;
  statusName: string;
  questionnaire: IQuestionerID;
  options: IProductsOptions;
}

export interface IProductsOptions {
  serviceId: number;
  name: string;
  price: number;
}

