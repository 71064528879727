import React from 'react';
import { useNavigate } from 'react-router-dom';
import LanguageForm from './LanguageForm';
import { createLanguage } from '../../stores/language';

const CreateLanguage = () => {
  const navigate = useNavigate();
  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createLanguage( data );
    navigate( '/settings/languages' );
  };
  return (
    <div className='editState'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Language</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/settings/languages' ) }>BACK TO
            LISTING
          </button>
        </div>
        <LanguageForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateLanguage;