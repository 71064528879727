import { apiGet, apiPost } from '../api';
import { objectToSearchParams } from './filter';
import { IChatMessage, ICreateChatMessage, IGoToChatRequest } from '../types/chats';

export type ChatEvent = ( message: IChatMessage ) => void;

let socketSubscribes: ChatEvent[] = [];

export const addSubscription = ( callback: ChatEvent ) => {
  socketSubscribes.push( callback );
};

export const removeSubscription = ( callback: ChatEvent ) => {
  socketSubscribes = socketSubscribes.filter( ( c ) => c !== callback );
};

export const getSubscriptions = () => {
  return socketSubscribes;
};

export const getChats = async ( params?: object ) => {
  let search = '';
  if ( params ) search = objectToSearchParams( params );

  return await apiGet( {
    url: `/chats?${ search }`,
  } );
};

export const getChat = async ( id?: string | number ) => {
  if ( !id ) return;

  return await apiGet( {
    url: `/chats/${ id }`,
  } );
};

export const sendMessage = async ( id: string | number, data: ICreateChatMessage | FormData ) => {
  return await apiPost( {
    url: `/chats/${ id }`,
    postData: data
  } );
};

export const goToChat = async ( data: IGoToChatRequest ) => {
  return await apiPost( {
    url: '/chats',
    postData: data
  } );
};
