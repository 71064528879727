import React, { useState } from 'react';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  onClick?: <T>() => Promise<T | void>;
}

const AsyncButton = ( { onClick, children, ...props }: IProps ) => {
  const [ disabled, setDisabled ] = useState( false );

  const click = async () => {
    setDisabled( true );
    try {
      if ( onClick ) await onClick();
      setDisabled( false );
    } catch ( e ) {
      setDisabled( false );
      throw e;
    }
  };

  return (
    <button type="button" onClick={ click } { ...props }>{ !disabled ? children : 'Loading...' }</button>
  );
};

export default AsyncButton;
