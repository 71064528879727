import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAdvertisementID, saveAdvertisement } from '../../stores/advertisement';
import Loader from '../../components/Loader';
import AdvertisementForm from './AdvertisementForm';

const EditAdvertisement = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: advertisementID, isLoading } = useQuery( [ 'advertisementID' ], () => getAdvertisementID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveAdvertisement( id, data );

    navigate( '/advertisement' );
  };


  return (
    <div className='editAdvertisement'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Banner</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/advertisement' ) }>BACK TO LISTING
          </button>
        </div>
        <AdvertisementForm save={ save } item={ advertisementID }/>
      </div>
    </div>
  );
};

export default EditAdvertisement;