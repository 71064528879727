import { apiGet, apiPost } from '../api';
import { ILanguage, ILanguages } from '../types/language';
import { createEvent, createStore } from 'effector';


export const getListLanguages = async () => {
  const responce = await apiGet<ILanguages>( { url: '/languages' } );
  updateLanguages( responce.items );
  return responce;
};

export const getLanguage = async ( id?: string ) => {
  return await apiGet<ILanguage>( { url: `/languages/${ id }` } );
};

export const saveLanguage = async ( id: string | number, data: any ) => {
  return await apiPost<ILanguage>( {
    url: `/languages/${ id }`,
    postData: data
  } );
};

export const createLanguage = async ( data: any ) => {
  return await apiPost<ILanguage>( {
    url: '/languages',
    postData: data
  } );
};

export const updateLanguages = createEvent<ILanguage[]>();

export const $languages = createStore<ILanguage[]>( [] )
  .on( updateLanguages, ( s, p ) => p );
