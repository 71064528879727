import React, { useEffect, useRef, useState } from 'react';
import { GoCheckCircle, GoCheckCircleFill, GoGear, GoXCircleFill } from 'react-icons/go';
import { BsPencilSquare } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

interface IProps {
  itemId: number | string;
  deleteItem?: ( id: number | string ) => void;
  activateItem?: ( id: number | string ) => void;
  deactivateItem?: ( id: number | string ) => void;
  afterChange?: ( id: number | string ) => void;
}

const ButtonSettings = ( { itemId, deactivateItem, deleteItem, activateItem, afterChange }: IProps ) => {
  const navigate = useNavigate();
  const button = useRef<HTMLButtonElement>( null );
  const [ open, setOpen ] = useState( false );

  const hasOnlyButton = !deactivateItem && !deleteItem && !activateItem;

  useEffect( () => {
    const clickWithout = ( e: any ) => {
      if ( open && e.target !== button.current ) {
        setOpen( false );
      }
    };
    document.addEventListener( 'click', clickWithout );
    return () => window.removeEventListener( 'click', clickWithout );
  }, [ open ] );

  return (
    <div className='buttonSettings'>
      { hasOnlyButton &&
        <Link to={ `${ itemId }` }>
          <BsPencilSquare className='thIcon'/>
        </Link>
      }

      { !hasOnlyButton && open &&
        <div className='column btnSettings'>
          <Link to={ `${ itemId }` }>
            <BsPencilSquare className='thIcon'/>
          </Link>
          { activateItem &&
            <button type="button" onClick={ async () => {
              await activateItem( itemId );
              if ( afterChange ) afterChange( itemId );
            } }>
              <GoCheckCircleFill className='thIconGreen'/>
            </button>
          }
          { deactivateItem &&
            <button type="button" onClick={ async () => {
              await deactivateItem( itemId );
              if ( afterChange ) afterChange( itemId );
            } }>
              <GoCheckCircle className='thIcon'/>
            </button>
          }
          { deleteItem &&
            <button type="button" onClick={ async () => {
              await deleteItem( itemId );
              if ( afterChange ) afterChange( itemId );
            } }>
              <GoXCircleFill className='thIconRed'/>
            </button>
          }
        </div>
      }
      { !hasOnlyButton &&
        <button ref={ button } type="button" onClick={ () => setOpen( !open ) }>
          <GoGear className='thIcon' style={ { pointerEvents: 'none' } }/>
        </button>
      }
    </div>
  );
};

export default ButtonSettings;
