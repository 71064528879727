import React from 'react';
import './dashboardPage.scss';
import MemberStat from './components/MemberStat';
import OrdersCountInMonth from './components/OrdersCountInMonth';
import TotalOrders from './components/TotalOrders';
import ContactUs from './components/ContactUs';
import SosRequests from './components/SosRequests';
import OrdersStat from './components/OrdersStat';
import ClientsChart from './components/ClientsChart';
import PaymentsRequests from './components/PaymentsRequests';
import NotificationAlerts from './components/NotificationAlerts';

const Dashboard = () => {
  return (
    <div className='dashboardPage column'>

      <div className='row justify-between'>
        <div className='column containerContent width-55'>
          <MemberStat/>
        </div>
        <div className='column containerContent width-45'>
          <OrdersCountInMonth/>
        </div>
      </div>

      {/*<div className='row justify-between'>*/ }
      {/*  <AdminEarning/>*/ }
      {/*  <EarningReport/>*/ }
      {/*</div>*/ }

      <div className='row justify-between'>
        <TotalOrders/>
        <OrdersStat/>
      </div>

      <div className='row justify-between'>
        <ContactUs/>
        <SosRequests/>
      </div>

      <PaymentsRequests/>

      <NotificationAlerts/>

      <ClientsChart/>
    </div>
  );
};

export default Dashboard;
