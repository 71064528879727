import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createQuestionnaire } from '../../stores/questionnaires';
import QuestionnaireForm from './QuestionnaireForm';


const CreateQuestionnaires = () => {
  const navigate = useNavigate();
  
  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createQuestionnaire( data );
  };

  return (
    <div className='editQuestionnaires'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Questioner</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/questionnaires' ) }>BACK TO LISTING
          </button>
        </div>
        <QuestionnaireForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateQuestionnaires;
