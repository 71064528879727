import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createTags } from '../../stores/questionnaires';
import TagForm from './TagForm';

const CreateTag = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createTags( data );
    navigate( '/manageServices/tags' );
  };

  return (
    <div className='editTags'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Tag</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/tags' ) }>BACK TO
            LISTING
          </button>
        </div>
        <TagForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateTag;
