import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createCustomer } from '../../stores/customers';
import CustomerForm from './CustomerForm';

const CreateCustomers = () => {
  const navigate = useNavigate();
  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createCustomer( data );
  };

  return (
    <div className='editCustomer'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Customer</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/customers' ) }>BACK TO LISTING
          </button>
        </div>
        <CustomerForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateCustomers;