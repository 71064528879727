import React from 'react';

import Table from '../components/Table';
import { GoCheckCircleFill } from 'react-icons/go';

import { useQuery } from 'react-query';
import Loader from '../components/Loader';
import AlertQuestionnairesFilter from '../components/filter/AlertQuestionnairesFilter';
import { useFilter } from '../stores/filter';
import { IAlerts } from '../types/alerts';
import { blockOwner, getAlerts } from '../stores/alerts';
import { showProfileQuestionnaire } from '../stores/modalContainer';

const AlertQuestionnaires = () => {
  const filter = useFilter();

  const {
    data: alerts,
    isLoading,
    refetch
  } = useQuery<IAlerts>( [ 'alerts', filter.toString() ], () => getAlerts( filter ) );

  const block = async ( id?: string ) => {
    if ( id ) blockOwner( id );
    refetch();
  };

  return (
    <div className='alertQuestionnaires'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Decline/Cancelled Orders Alert For Questionnaires</div>
        <AlertQuestionnairesFilter/>
        { isLoading ?
          <Loader position={ 'static' }/>
          :
          <Table
            items={ alerts?.items || [] }
            count={ alerts?.count }
            fields={ {
              username: 'Name',
              email: 'E-mail',
              totalInDay: 'Cancelled Trips (in 24 hours)',
              total: 'Total Cancelled Trips (till now)',
              date: 'Block Date',
              block: 'Block Questionnaire'
            } }
            headFieldsClassName={ {
              date: 'thText-center',
              block: 'thText-center'
            } }
            bodyFieldsClassName={ {
              date: 'thText-center',
              block: 'thText-center'
            } }
            columnBody={ {
              name: ( item ) =>
                <button onClick={ () => showProfileQuestionnaire( item?.id ) } className='linkMassage'>
                  { item?.username }
                </button>,
              block: ( item ) =>
                <div className='d-flex align-center justify-center'>
                  <button className='row align-center justify-center btnBlock'
                          onClick={ () => block( item?.id ) }>
                    <GoCheckCircleFill className='iconBlock'/>
                    No
                  </button>
                </div>
            } }
          />
        }
      </div>
    </div>
  );
};

export default AlertQuestionnaires;