import React from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../styles/reviews.scss';
import Table from '../components/Table';
import { useQuery } from 'react-query';
import { IReview, IReviews } from '../types/reviews';
import { getListReviews } from '../stores/reviews';
import ReviewsFilter from '../components/filter/ReviewsFilter';
import { useFilterParams } from '../stores/filter';
import Loader from '../components/Loader';
import { Rating } from 'react-simple-star-rating';

const Reviews = () => {
  const [ filter, changeFilter ] = useFilterParams();

  const {
    data: reviews,
    isLoading
  } = useQuery<IReviews>( [ 'reviews', filter.toString() ], () => getListReviews( filter ) );

  return (
    <div className='reviews'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Reviews</div>
        <ReviewsFilter/>
        <div className='column containerIn mb-10'>
          <Tabs onSelect={ ( tab ) => changeFilter( 'type' )( { value: !tab ? 'questionnaire' : 'customer' } ) }>
            <TabList className='greyHeader border-bottom'>
              <div className='row width-23 absolut'>
                <Tab>Questionnaires</Tab>
                <Tab>Customers</Tab>
              </div>
            </TabList>
            <div className='row mb-10'>
              <TabPanel>
                { isLoading ?
                  <Loader position={ 'static' }/>
                  :
                  <Table
                    items={ reviews?.items || [] }
                    count={ reviews?.count }
                    fields={ {
                      // id: 'Order Number',
                      name: 'Questionnaire',
                      aboutName: 'Review about',
                      rating: 'Rating',
                      created_at: 'Date',
                      comment: 'Comment'
                    } }
                    bodyFieldsClassName={ {
                      comment: 'width-35'
                    } }
                    headFieldsClassName={ {
                      comment: 'width-35'
                    } }
                    columnBody={ {
                      name: ( row: IReview ) => row.owner.username,
                      aboutName: ( row: IReview ) => row.target.username,
                      rating: ( row: IReview ) => <Rating
                        fillColor={ '#ff9811' }
                        emptyColor={ '#fff' }
                        size={ 25 }
                        initialValue={ row.rating }
                        readonly
                      />
                    } }
                  />
                }
              </TabPanel>
              <TabPanel>
                { isLoading ?
                  <Loader position={ 'static' }/>
                  :
                  <Table
                    items={ reviews?.items || [] }
                    count={ reviews?.count }
                    fields={ {
                      // id: 'Order Number',
                      name: 'Customer',
                      aboutName: 'Review about',
                      rating: 'Rating',
                      orderDate: 'Date',
                      comment: 'Comment'
                    } }
                    bodyFieldsClassName={ {
                      comment: 'width-35'
                    } }
                    headFieldsClassName={ {
                      comment: 'width-35'
                    } }
                    columnBody={ {
                      name: ( row: IReview ) => row.owner.username,
                      aboutName: ( row: IReview ) => row.target.username,
                      rating: ( row: IReview ) => <Rating
                        fillColor={ '#ff9811' }
                        emptyColor={ '#fff' }
                        size={ 25 }
                        initialValue={ row.rating }
                        readonly
                      />
                    } }
                  />
                }
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Reviews;