import React from 'react';
import { staticResource } from '../../utils/resources';

interface IProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  containerClassName?: string;
  label?: string;
}

const ImageField = ( { containerClassName, label, ...props }: IProps ) => {
  return (
    <div className={ containerClassName }>
      <div className='boldTextEdit mb-10'>
        { label }
        { props.content && <span className='red-star'>*</span> }
      </div>
      <div>
        <img src={ staticResource( String( props.defaultValue ), 200, 200 ) } className='imgEditFile' { ...props }/>
      </div>
    </div>
  );
};

export default ImageField;