export const textLoginPage: any = {
  title: 'Login in to Dashboard',
  wellcome: 'Welcome to Admin Panel',
  subWelcome: 'Monitor your server usage & services as well as manage applications & front website.',
  button1: 'All Admin',
  button2: 'Dispatcher Admin',
  button3: 'Billing Admin',
  button4: 'Server Admin',
  email: 'Admin E-mail',
  password: 'Password',
  signin: 'SIGN IN'
}