import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { useStore } from 'effector-react';
import { $languages } from '../../stores/language';
import { useQuery } from 'react-query';
import { getRegion, saveRegion } from '../../stores/geo';
import Loader from '../../components/Loader';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const EditRegion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const languages = useStore( $languages );
  const form = useRef<HTMLFormElement>( null );

  const { data: item, isLoading } = useQuery( [ 'region', id ], () => getRegion( String( id ) ) );
  if ( isLoading ) return <Loader/>;

  const onClick = async () => {
    if ( id && form.current )
      await saveRegion( id, new FormData( form.current ) );
  };

  return (
    <div className='editState'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Region</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/localization/regions' ) }>BACK TO
            LISTING
          </button>
        </div>
        <form ref={ form }>
          <Tabs>
            <TabList>
              <div className='row'>
                { languages.map( language =>
                  <Tab key={ language.id }>{ language.name }</Tab>
                ) }
              </div>
            </TabList>
            <div className='row mt-40 mb-10 border-top'>
              { languages.map( language =>
                <TabPanel key={ language.id } className='row mt-10'>
                  <CustomForm
                    key={ item?.id }
                    item={ item }
                    fields={ {
                      name: {
                        name: 'Name',
                        inputName: `name.${ language.language }`,
                        type: 'input',
                        props: {
                          containerClassName: 'width-50',
                          required: true
                        }
                      },
                    } }/>
                </TabPanel>
              ) }
            </div>
          </Tabs>

          <ButtonsCustomForm onClick={ onClick } group={ 'geo' }/>
        </form>
      </div>
    </div>
  );
};

export default EditRegion;