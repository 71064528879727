import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCategory, saveCategory } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import CategoryForm from './CategoryForm';

const EditCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: category, isLoading } = useQuery( [ 'category', id ], () => getCategory( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveCategory( id, data );

    navigate( '/manageServices/categories' );
  };

  return (
    <div className='editCategories'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit { category?.name?.en }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/categories' ) }>BACK TO
            LISTING
          </button>
        </div>
        <CategoryForm save={ save } item={ category }/>
      </div>
    </div>
  );
};

export default EditCategories;
