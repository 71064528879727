import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import '../../styles/search.scss';
import { useFilterParams } from '../../stores/filter';
import WithdrawStatus from '../selectors/WithdrawStatus';


const options = [
  { value: '', label: 'All' },
  { value: 'username', label: 'Name' },
  { value: 'email', label: 'E-mail' },
  { value: 'telephone', label: 'Mobile' },
];

const WithdrawFilter = () => {
  const [ filter, changeFilter, clearFilter ] = useFilterParams();

  const [ selected, setSelect ] = useState( options[ 0 ] );

  useEffect( () => {
    let sel = options[ 0 ];
    options.map( o => {
      if ( filter[ o.value ] ) {
        sel = o;
      }
    } );
    setSelect( sel );
  }, [] );

  const changeSelect = ( value: any ) => {
    changeFilter( selected.value, value )( '' );
    setSelect( value );
  };


  return (
    <div className='search column justify-center'>
      <div className='row align-center width-100'>
        <div className='row width-70 align-center gap-10'>
          <div className='searchTitle'>Search</div>
          <Select
            key={ `select_${ selected.value }` }
            className='selector-small width-20'
            defaultValue={ selected }
            onChange={ changeSelect }
            options={ options }
          />
          <input
            key={ selected.value }
            type='text'
            className='input width-30'
            defaultValue={ filter[ selected.value ] }
            onChange={ changeFilter( selected.value ) }
            disabled={ selected.value === '' }
          />
          <WithdrawStatus/>
          <button type='button' className='btn' onClick={ clearFilter }>RESET</button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawFilter;
