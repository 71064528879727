import React from 'react';
import { GoNoEntry } from 'react-icons/go';
import { HiExclamationCircle } from 'react-icons/hi';
import { EBrothelStatus } from '../../types/brothels';

interface IProps {
  status: any | boolean | string | number;
}

const Status = ( { status }: IProps ) => {
  return (
    <div className='status'>
      { status === EBrothelStatus.CONFIRMED &&
        <HiExclamationCircle className='thIconGreen'/>
      }

      { ( status === EBrothelStatus.REJECTED || status === EBrothelStatus.CREATED ) &&
        <GoNoEntry className='thIconRed'/>
      }
    </div>
  );
};

export default Status;
