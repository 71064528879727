import React, { useState } from 'react';
import '../styles/reviews.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Table from '../components/Table';
import { useQuery } from 'react-query';
import { ECustomerRole, ICustomers } from '../types/customers';
import { getListCustomers } from '../stores/customers';
import Loader from '../components/Loader';
import { FilterParams } from '../stores/filter';

const Referral = () => {
  const [ roleId, setRoleId ] = useState( ECustomerRole.CLIENT );
  const { data: customers, isLoading } = useQuery<ICustomers>(
    [ 'customers', roleId ],
    () => getListCustomers( new FilterParams( { roleId, includeReferalCount: true } ) )
  );

  return (
    <div className='referral'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Referral Report</div>
        <div className='column containerIn mb-10'>
          <Tabs onSelect={ ( value ) => setRoleId( value ? ECustomerRole.MODEL : ECustomerRole.CLIENT ) }>
            <TabList className='greyHeader border-bottom'>
              <div className='row width-23 absolut'>
                <Tab>Customers</Tab>
                <Tab>Questionnaires</Tab>
              </div>
            </TabList>
            <div className='row mb-10'>
              <TabPanel>
                { isLoading ?
                  <Loader/>
                  :
                  <Table
                    items={ customers?.items || [] }
                    count={ customers?.count }
                    fields={ {
                      username: 'Member Name',
                      referalsCount: 'Total Members Referred',
                      totalAmountEarned: 'Total Amount Earned',
                      // detail: 'Detail'
                    } }
                    columnBody={ {
                      username: ( item ) => item.username || item.email
                    } }
                  />
                }
              </TabPanel>
              <TabPanel>
                { isLoading ?
                  <Loader/>
                  :
                  <Table
                    items={ customers?.items || [] }
                    count={ customers?.count }
                    fields={ {
                      username: 'Member Name',
                      referalsCount: 'Total Members Referred',
                      totalAmountEarned: 'Total Amount Earned',
                      // detail: 'Detail'
                    } }
                    columnBody={ {
                      username: ( item ) => item.username || item.email
                    } }
                  />
                }
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Referral;