import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { ISmsTemplate } from '../../types/notifications';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: ISmsTemplate;
}

const SmsTemplateForm = ( { save, item }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form
      ref={ form }
      key={ item?.id }
    >
      <CustomForm
        item={ item }
        fields={ {
          body: {
            name: 'Template',
            subTitle: 'short-code: ' + item?.shortCodes,
            type: 'textarea',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
        } }/>

      <ButtonsCustomForm onClick={ onClick } group={ 'smsTemplates' }/>
    </form>
  );
};

export default SmsTemplateForm;