import { apiGet, apiPost } from '../api';
import { FilterParams } from './filter';
import { ISmsTemplate, ISmsTemplates } from '../types/notifications';

export const getSmsTemplates = async ( filter?: FilterParams ) => {
  return await apiGet<ISmsTemplates>( { url: `/smsTemplates?${ filter?.toQueryParams() }` } );
};

export const getSmsTemplate = async ( id: string | number ) => {
  return await apiGet<ISmsTemplate>( { url: `/smsTemplates/${ id }` } );
};

export const saveSmsTemplate = async ( id: string, data: any ) => {
  return await apiPost<ISmsTemplate>( {
    url: `/smsTemplates/${ id }`,
    postData: data
  } );
};
