import React from 'react';
import Select from 'react-select';
import '../../styles/search.scss';
import { useFilterParams } from '../../stores/filter';
import { EWithdrawStatus } from '../../types/withdrawRequests';

const options = [
  { value: '', label: 'Select status' },
  { value: EWithdrawStatus.CREATED, label: 'New' },
  { value: EWithdrawStatus.CONFIRMED, label: 'Confirmed' },
  { value: EWithdrawStatus.REJECTED, label: 'Rejected' },
];

interface IProps {
  width?: string;
  defaultValue?: EWithdrawStatus;
  onChange?: ( value: { value: EWithdrawStatus } ) => void;
  disabled?: boolean;
}

const WithdrawStatus = ( { width: string, defaultValue, onChange, disabled }: IProps ) => {
  const [ filter, changeFilter ] = useFilterParams();

  if ( defaultValue === undefined ) defaultValue = filter.status;

  return (
    <div style={ { width: string } }>
      <Select
        key={ defaultValue }
        className='selector-large width-100'
        defaultValue={ options.find( o => o.value === defaultValue ) || options[ 0 ] }
        onChange={ !onChange ? changeFilter( 'status' ) : onChange }
        options={ options }
        isDisabled={ disabled }
      />
    </div>
  );
};

export default WithdrawStatus;
