import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAttributes } from '../../stores/questionnaires';
import AttributeForm from './AttributeForm';

const CreateAttribute = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createAttributes( data );
    navigate( '/manageServices/attributes' );
  };

  return (
    <div className='editAttributes'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Attribute</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/attributes' ) }>BACK TO
            LISTING
          </button>
        </div>
        <AttributeForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateAttribute;
