import React from 'react';
import Table from '../../components/Table';
import { BsFillFileEarmarkMinusFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { IBrothels } from '../../types/brothels';
import { activateBrothel, deactivateBrothel, deleteBrothel, getListBrothels } from '../../stores/brothels';
import Loader from '../../components/Loader';
import Status from './Status';
import BrothelFilter from '../../components/filter/BrothelFilter';
import { useFilter } from '../../stores/filter';
import ButtonSettings from '../../components/ButtonSettings';
import { Rating } from 'react-simple-star-rating';

const Brothels = () => {
  const filter = useFilter();

  const {
    data: brothels,
    isLoading,
    refetch
  } = useQuery<IBrothels>( [ 'brothels', filter.toString() ], () => getListBrothels( filter ) );

  return (
    <div className='company'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Brothels</div>
        <BrothelFilter/>
        { isLoading ?
          <Loader position={ 'static' }/>

          :
          <Table
            items={ brothels?.items || [] }
            count={ brothels?.count }
            fields={ {
              name: 'Company Name',
              questionnairesCount: 'Questionnaires',
              rating: 'Rating',
              address: 'Address',
              telephone: 'Telephone',
              verification: 'View/Edit Documents',
              status: 'Status',
              actions: 'Actions'
            } }
            headFieldsClassName={ {
              address: 'width-20',
              verification: 'thText-center',
              status: 'thText-center',
              actions: 'thText-center'
            } }
            bodyFieldsClassName={ {
              verification: 'thText-center',
              status: 'thText-center',
              actions: 'thText-center'
            } }
            columnBody={ {
              verification: ( item ) => ( item.verification ?
                <BsFillFileEarmarkMinusFill className='thIcon'/> : '' ),
              status: ( item ) => <Status status={ item.status }/>,
              rating: ( item ) => <Rating
                fillColor={ '#ff9811' }
                emptyColor={ '#fff' }
                size={ 25 }
                initialValue={ item?.rating }
                readonly
              />,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings
                    itemId={ item.id }
                    deleteItem={ deleteBrothel }
                    activateItem={ activateBrothel }
                    deactivateItem={ deactivateBrothel }
                    afterChange={ () => refetch() }
                  />
                </div>
            } }
          />
        }
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default Brothels;
