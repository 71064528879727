import { apiGet } from '../api';
import { IPermission, IPermissions } from '../types/permissions';
import { createEvent, createStore } from 'effector';

export const getListPermissions = async () => {
  return await apiGet<IPermissions>( { url: '/permissions' } );
};

export const getPermission = async ( id: number ) => {
  return await apiGet<IPermission>( { url: `/permissions/${ id }` } );
};


// мы создаем тип, у которого есть f - get проверяет доступ, получая аргументами группу и возможные действия + f обновления разрешений
export interface IPermissionsService {
  get: ( group: string, action: string ) => boolean;
  getIn: ( permission: string | string[] ) => boolean; // `group:access` format
  updatePermissions: ( permissions: any ) => void;
}

//тут мы описываем, как класс работает, он работает как f
function UserPermissions( permissions: any[] ) {
  // чтобы защитить переменную permissionStore от изменений, мы создаем ее внутри класса. то есть, глобальную переменную permission нельзя использовать теперь вот так permission.permissionStore
  let permissionStore: any = {};

  // @ts-ignore если есть this - эта f доступна из вне например  вот так permission.updatePermissions(arr)
  this.updatePermissions = ( permissions?: any[] ) => {
    permissions?.forEach( ( s: any ) => {
      // если в permissionStore отсутствует такая группа, то создается пустой объект
      if ( !permissionStore[ s.group ] ) {
        permissionStore[ s.group ] = {};
      }
      // здесь в этот объеект записывается группа и разрешенные действия, по умолчанию true
      permissionStore[ s.group ][ s.action ] = true;
    } );
  };

  // @ts-ignore  тепрь мы можем все выше написанное использовать и обновлять стор let permissionStore: any = {};
  this.updatePermissions( permissions );

  // @ts-ignore
  this.get = ( group: string, action: string ) => {
    if ( !group && !action ) return true;
    if ( !permissionStore[ group ] ) return false;
    return permissionStore[ group ][ action ];
  };
  // @ts-ignore
  this.getIn = ( permission: string | string[] ) => {
    let accessStatus = false;
    if ( typeof permission === 'string' ) {
      permission = [ permission ];
    }
    permission?.forEach( ( access ) => {
      const [ group, action ] = access.split( ':' );
      // @ts-ignore
      if ( this.get( group, action ) ) {
        accessStatus = true;
      }
    } );

    return accessStatus;
  };
}

export const updatePermissions = createEvent<IPermission[]>();

//@ts-ignore
export const $permissions = createStore<IPermissionsService>( new UserPermissions( [] ) )
  .on( updatePermissions, ( state, payload ) => {
    //@ts-ignore
    return new UserPermissions( payload );
  } );

