import { apiDelete, apiGet, apiPost } from '../api';
import { EBrothelStatus, IBrothelID, IBrothels } from '../types/brothels';
import { FilterParams } from './filter';


export const getListBrothels = async ( filter?: FilterParams ) => {
  return await apiGet<IBrothels>( { url: `/brothels?${ filter?.toQueryParams() }` } );
};

export const getBrothelID = async ( id?: string ) => {
  return await apiGet<IBrothelID>( { url: `/brothels/${ id }`, } );
};

export const deleteBrothel = async ( id: string | number ) => {
  return await apiDelete<{}>( { url: `/brothels/${ id }`, } );
};

export const activateBrothel = async ( id: string | number ) => {
  return await apiPost<IBrothelID>( {
    url: `/brothels/${ id }`,
    postData: {
      status: EBrothelStatus.CONFIRMED,
    }
  } );
};

export const deactivateBrothel = async ( id: string | number ) => {
  return await apiPost<IBrothelID>( {
    url: `/brothels/${ id }`,
    postData: {
      status: EBrothelStatus.REJECTED,
    }
  } );
};

export const saveBrothels = async ( id: string, data: any ) => {
  return await apiPost<IBrothelID>( {
    url: `/brothels/${ id }`,
    postData: data
  } );
};

export const createBrothels = async ( data: any ) => {
  return await apiPost<IBrothelID>( {
    url: '/brothels',
    postData: data
  } );
};
