import { apiGet } from '../api';
import { IClientsStat, IDashboardCounts, IOrdersCount, IOrdersInStatus, IOrdersStat } from '../types/statistic';

export const getEntityCounts = async () => {
  return await apiGet<IDashboardCounts>( { url: '/dashboard/entities' } );
};

export const getOrdersCountInMonth = async () => {
  return await apiGet<IOrdersCount>( { url: '/dashboard/orders' } );
};

export const getOrdersCountInStatus = async () => {
  return await apiGet<IOrdersInStatus[]>( { url: '/dashboard/orders/status' } );
};

export const getOrdersStat = async ( year: string ) => {
  return await apiGet<IOrdersStat[]>( { url: `/dashboard/orders/stat?year=${ year }` } );
};

export const getClientsStat = async ( year: string ) => {
  return await apiGet<IClientsStat[]>( { url: `/dashboard/clients/stat?year=${ year }` } );
};
