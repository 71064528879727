import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IBrothelID } from '../../types/brothels';
import { getBrothelID, saveBrothels } from '../../stores/brothels';
import Loader from '../../components/Loader';
import BrothelForm from './BrothelForm';

const EditBrothels = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: brothelID, isLoading } = useQuery<IBrothelID>( [ 'brothelID' ], () => getBrothelID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveBrothels( id, data );
    navigate( '/brothels' );
  };

  return (
    <div className='editBrothels'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Brothel { brothelID?.name }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/brothels' ) }>BACK TO LISTING
          </button>
        </div>
        <BrothelForm save={ save } item={ brothelID }/>
      </div>
    </div>
  );
};

export default EditBrothels;
