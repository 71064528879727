import React from 'react';
import { useQuery } from 'react-query';
import { getTagsList } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import ButtonSettings from '../../components/ButtonSettings';
import Status from '../../components/status/Status';
import TagsFilter from '../../components/filter/TagsFilter';
import SelectorActions from '../../components/selectors/SelectorActions';
import { useFilter } from '../../stores/filter';

const TagsList = () => {

  const filter = useFilter();

  const { data: tags, isLoading } = useQuery( [ 'tags', filter.toString() ], () => getTagsList( filter ) );

  return (
    <div className='tags'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Tags</div>
        <TagsFilter/>
        <SelectorActions/>
        { isLoading ?
          <Loader position={ 'static' }/>

          :
          <Table
            items={ tags?.items || [] }
            count={ tags?.count }
            fields={ {
              name: 'Name',
              status: 'Status',
              actions: 'Actions'
            } }
            bodyFieldsClassName={ {
              status: 'thText-center',
              actions: 'thText-center'
            } }
            headFieldsClassName={ {
              status: 'thText-center',
              actions: 'thText-center'
            } }
            columnBody={ {
              status: ( item ) => <Status status={ item.status }/>,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings itemId={ item.id }/>
                </div>
            } }
          />
        }
      </div>
    </div>
  );
};

export default TagsList;