import { apiGet, apiPost } from '../api';
import { FilterParams } from './filter';
import { IAreaByID, IAreas, ICities, ICityByID, ICountries, ICountryByID, IRegionByID, IRegions } from '../types/geo';

export const getCities = async ( filter?: FilterParams ) => {
  return await apiGet<ICities>( { url: `/geo/cities?${ filter?.toQueryParams() }` } );
};

export const getCity = async ( id: string | number ) => {
  return await apiGet<ICityByID>( { url: `/geo/cities/${ id }` } );
};

export const saveCity = async ( id: string, data: any ) => {
  return await apiPost<ICityByID>( {
    url: `/geo/cities/${ id }`,
    postData: data
  } );
};


export const getAreas = async ( filter?: FilterParams ) => {
  return await apiGet<IAreas>( { url: `/geo/areas?${ filter?.toQueryParams() }` } );
};

export const getArea = async ( id: string | number ) => {
  return await apiGet<IAreaByID>( { url: `/geo/areas/${ id }` } );
};

export const saveArea = async ( id: string, data: any ) => {
  return await apiPost<IAreaByID>( {
    url: `/geo/areas/${ id }`,
    postData: data
  } );
};


export const getRegions = async ( filter?: FilterParams ) => {
  return await apiGet<IRegions>( { url: `/geo/regions?${ filter?.toQueryParams() }` } );
};

export const getRegion = async ( id: string | number ) => {
  return await apiGet<IRegionByID>( { url: `/geo/regions/${ id }` } );
};

export const saveRegion = async ( id: string, data: any ) => {
  return await apiPost<IRegionByID>( {
    url: `/geo/regions/${ id }`,
    postData: data
  } );
};


export const getCountries = async ( filter?: FilterParams ) => {
  return await apiGet<ICountries>( { url: `/geo/countries?${ filter?.toQueryParams() }` } );
};

export const getCountry = async ( id: string | number ) => {
  return await apiGet<ICountryByID>( { url: `/geo/countries/${ id }` } );
};

export const saveCountry = async ( id: string, data: any ) => {
  return await apiPost<ICountryByID>( {
    url: `/geo/countries/${ id }`,
    postData: data
  } );
};