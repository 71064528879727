import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { ILanguage } from '../../types/language';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: ILanguage;
}

const LanguageForm = ( { save, item }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <CustomForm
        key={ item?.id }
        item={ item }
        fields={ {
          name: {
            name: 'Language Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          language: {
            name: 'Language Code',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true,
              maxLength: 2
            }
          },
          country: {
            name: 'Country Code',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true,
              maxLength: 2
            }
          },
        } }/>
      <ButtonsCustomForm onClick={ onClick } group={ 'languages' }/>
    </form>
  );
};

export default LanguageForm;