import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAttributeGroup } from '../../stores/questionnaires';
import AttributeGroupForm from './AttributeGroupForm';

const CreateAttributeGroups = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createAttributeGroup( data );
    navigate( '/manageServices/attribute_groups' );
  };

  return (
    <div className='editAttributeGroups'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Attribute Group</div>
          <button className='btnAdd' type='button'
                  onClick={ () => navigate( '/manageServices/attribute_groups' ) }>BACK TO
            LISTING
          </button>
        </div>

        <AttributeGroupForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateAttributeGroups;
