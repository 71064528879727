import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ICustomerID } from '../../types/customers';
import { getCustomerID, saveCustomer } from '../../stores/customers';
import Loader from '../../components/Loader';
import CustomerForm from './CustomerForm';

const EditCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: customerID, isLoading } = useQuery<ICustomerID>( [ 'customerID' ], () => getCustomerID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveCustomer( id, data );
    navigate( '/customers' );
  };


  return (
    <div className='editCustomer'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Customer { customerID?.username || customerID?.email }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/customers' ) }>BACK TO LISTING
          </button>
        </div>
        <CustomerForm save={ save } item={ customerID }/>
      </div>
    </div>
  );
};

export default EditCustomer;