import React, { useRef } from 'react';
import '../../styles/editAdminGroups.scss';
import { IPermissionGroup } from '../../types/permissions';

interface IProps {
  selected?: { [ actionName: string ]: boolean };
  permissions: IPermissionGroup;
}

const Permission1 = ( { permissions, selected }: IProps ) => {
  const row = useRef<HTMLDivElement>( null );
  const selectAll = () => {
    row.current?.querySelectorAll( 'input' )?.forEach( e => {
      e.checked = true;
    } );
  };

  const unselectAll = () => {
    row.current?.querySelectorAll( 'input' )?.forEach( e => {
      e.checked = false;
    } );
  };

  return (
    <div ref={ row }>
      <div className='column containerContent mb-10'>
        <div className='greyTitle row justify-between align-center border-bottom'>
          <div className='colorBlack weight-600 h4'>{ permissions.group }</div>
          <div className='row width-50 justify-end align-center'>
            <div className='row justify-center align-center'>
              <button className='blueButton' type="button" onClick={ selectAll }>Select All</button>
              <button className='redButton' type="button" onClick={ unselectAll }>De-Select All</button>
            </div>
          </div>
        </div>
        <div className='redNote'>
          Note : Please make sure to select <b>view-</b> permission if you are selecting any other permission
          for any section.
        </div>
        <div className='row '>
          { permissions.actions.map( action =>
            <div key={ action.id } className='row padding20 justify-start align-center mb-10'>
              <input
                id={ `${ permissions.group }_${ action.id }` }
                name='permissions[]'
                value={ action.id }
                type='checkbox'
                defaultChecked={ selected && selected[ action.action ] }
              />
              <label
                htmlFor={ `${ permissions.group }_${ action.id }` }
                className='colorBlack weight-600'
              >{ action.action }</label>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default Permission1;
