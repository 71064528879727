import React from 'react';
import '../../styles/search.scss';
import { useFilterParams } from '../../stores/filter';


const AlertQuestionnairesFilter = () => {
  const [ filter, changeFilter, clearFilter ] = useFilterParams();


  return (
    <div className='search justify-center'>
      <div className='d-flex align-center width-100 gap-10'>

        <div className='searchTitle'>Search</div>

        <input
          type='text'
          className='input'
          defaultValue={ filter.name }
          onChange={ changeFilter( 'name' ) }
        />

        <button type='button' className='btn' onClick={ clearFilter }>RESET</button>

      </div>
    </div>
  );
};

export default AlertQuestionnairesFilter;