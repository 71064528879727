import React, { useEffect } from 'react';
import Table from '../../components/Table';
import { useStore } from 'effector-react';
import { $languages, getListLanguages } from '../../stores/language';
import ButtonSettings from '../../components/ButtonSettings';
import { Link } from 'react-router-dom';

const Languages = () => {
  const languages = useStore( $languages );

  useEffect( () => {
    getListLanguages().then();
  }, [] );

  return (
    <div className='language'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Language</div>

        <div className='row justify-end align-center width-100 mb-20'>
          <Link to='/settings/languages/create' className='colorW width-10'>
            <button type='button' className='btnAdd width-100'>
              ADD
            </button>
          </Link>
        </div>

        <Table
          items={ languages }
          fields={ {
            name: 'Language',
            language: 'Code',
            country: 'Country',
            action: ''
          } }
          headFieldsClassName={ {
            action: 'width-15'
          } }
          columnBody={ {
            action: ( item ) =>
              <div className='padding5'>
                <ButtonSettings itemId={ item.id }/>
              </div>
          } }
        />
      </div>
    </div>
  );
};

export default Languages;